import React from 'react';
import { BaseDialog } from '@/react/components/dialog';

export interface StartPlanDisabledDialogProps {
  onClose: () => void;
  open: boolean;
}

export const NewPlanDisabledDialog = ({
  onClose,
  open,
}: StartPlanDisabledDialogProps) => {
  return (
    <BaseDialog
      data-testid="new-plan-disabled-dialog"
      onClose={onClose}
      open={open}
      title="Você não pode criar um novo plano"
    >
      Só é possível ter um plano em rascunho e um em andamento simultaneamente.
    </BaseDialog>
  );
};
