import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AccordionStep } from '@/react/components/refund/accordion/interfaces';
import { useAccordion } from '@/react/components/refund/accordion/state/useAccordion';
import {
  AccordionContent,
  AccordionHeader,
} from '@/react/components/refund/accordion';

const Styled = {
  Accordion: styled(MuiAccordion)(({ theme }) => ({
    '&': {
      backgroundColor: theme.palette.neutral[50],
      borderColor: theme.palette.neutral[400],
      borderStyle: 'solid',
      borderWidth: 1,

      '&.Mui-expanded': {
        backgroundColor: theme.palette.neutral[50],
      },

      '&:before': {
        content: 'none',
      },

      '&.Mui-disabled': {
        backgroundColor: 'transparent',
      },
    },
  })),
};

const idsNotExpandable = ['resourceApproval', 'invoiceValidation', 'payment'];

const AccordionItem = ({ id, step }: { id: string; step: AccordionStep }) => {
  const { actions } = useAccordion();
  const { disabled, expanded, renderContent, renderHeader, status } = step;

  const handleExpand =
    (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      !idsNotExpandable.includes(id) &&
        (isExpanded ? actions.open(id) : actions.close(id));
    };

  return (
    <Styled.Accordion
      className={status ? `status status--${status}` : ''}
      disabled={disabled}
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={handleExpand(id)}
      square
      sx={{ mb: 3 }}
    >
      <AccordionSummary
        expandIcon={!idsNotExpandable.includes(id) ? <ExpandMoreIcon /> : null}
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
      >
        <AccordionHeader status={status}>{renderHeader()}</AccordionHeader>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{ mb: 3 }} />
        <AccordionContent>
          {!!renderContent && renderContent()}
        </AccordionContent>
      </AccordionDetails>
    </Styled.Accordion>
  );
};

export default AccordionItem;
