import { ResourceResponseType } from './interfaces';

export class Resource {
  courseName: string;
  courseId: number;
  institutionId: number;
  institutionUuid: string;
  institutionName: string;

  constructor(args: Resource) {
    this.courseId = args.courseId;
    this.courseName = args.courseName;
    this.institutionId = args.institutionId;
    this.institutionName = args.institutionName;
    this.institutionUuid = args.institutionUuid;
  }
  static fromServerPayload(data: ResourceResponseType): Resource {
    return new Resource({
      courseId: data.course.id,
      courseName: data.course.name,
      institutionId: data.institution.id,
      institutionName: data.institution.institution,
      institutionUuid: data.institution.uuid,
    });
  }
}
