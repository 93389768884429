import * as React from 'react';
import Box from '@mui/material/Box';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';

import { useClickOutside } from '@/react/hooks/useClickOutside';

export type InlineEditInputProps = {
  children?: React.ReactNode;
  onAccept: (value: string) => Promise<boolean>;
  onClickOutside?: (value: string) => Promise<boolean>;
  onCancel?: () => void;
  disabled?: boolean;
  value: string;
} & InputBaseProps;

const Modes = {
  editing: 'editing',
  pending: 'pending',
  readOnly: 'readOnly',
} as const;

type ModeTypes = keyof typeof Modes;

export const InlineEditInput = React.forwardRef<
  HTMLInputElement,
  InlineEditInputProps
>(
  (
    {
      children,
      disabled,
      onAccept,
      onCancel,
      onChange,
      onClickOutside,
      placeholder,
      value,
      ...props
    },
    ref
  ) => {
    const [mode, setMode] = React.useState<ModeTypes>(Modes.readOnly);
    const [internalValue, setInternalValue] = React.useState(value);
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const wrapperRef = React.useRef<HTMLDivElement | null>(null);

    const isDirty = mode === Modes.editing && value !== internalValue;
    const displayValue = mode === Modes.readOnly ? value : internalValue;

    React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const discardChanges = () => {
      setInternalValue(value);
    };

    const handleEditMode = (active: boolean) => {
      if (active) {
        if (disabled || mode === Modes.pending) return inputRef.current.blur();

        setMode(Modes.editing);

        inputRef.current.focus();
      } else {
        if (mode !== Modes.editing) return;

        setMode(Modes.readOnly);
        inputRef.current.blur();
      }
    };

    const handleCancel = () => {
      handleEditMode(false);
      discardChanges();

      if (typeof onCancel === 'function') {
        onCancel();
      }
    };

    const handleAccept = async () => {
      if (!isDirty || mode !== Modes.editing) return handleEditMode(false);

      setMode(Modes.pending);
      await onAccept(internalValue);

      inputRef.current.blur();
      setMode(Modes.readOnly);
    };

    const handleEnterKeypress = async () => {
      if (mode === Modes.readOnly) return handleEditMode(true);
      if (mode === Modes.editing) {
        if (!isDirty) return handleEditMode(false);
        if (mode === Modes.editing) return handleAccept();
      }
    };

    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'Enter':
          e.preventDefault();
          handleEnterKeypress();
          break;
        case 'Escape':
        case 'Tab':
          e.stopPropagation();
          handleCancel();
          break;
        default:
          break;
      }
    };

    const handleClickOutside = async () => {
      if (!isDirty || mode !== Modes.editing) return handleEditMode(false);

      if (typeof onClickOutside === 'function') {
        await onClickOutside(internalValue);
      }
      setMode(Modes.readOnly);
    };

    useClickOutside({
      callback: handleClickOutside,
      ref: wrapperRef,
    });

    React.useEffect(() => {
      if (mode === Modes.readOnly) {
        discardChanges();
      }
    }, [value]);

    return (
      <Box
        ref={wrapperRef}
        sx={(theme) => ({
          position: 'relative',
          '&:hover': { backgroundColor: theme.palette.neutral[300] },
        })}
      >
        <InputBase
          {...props}
          className={''}
          fullWidth
          inputProps={{ className: props.className }}
          inputRef={inputRef}
          multiline
          onChange={(e) => {
            setInternalValue(e.target.value);
          }}
          onClick={() => handleEditMode(true)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          readOnly={mode !== Modes.editing}
          sx={(theme) => ({
            '& .MuiInputBase-input': {
              '&:focus': {
                boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
              },
            },
          })}
          value={displayValue}
        />
        {mode === Modes.editing && (
          <Stack
            sx={{
              bottom: -22,
              position: 'absolute',
              right: 2,
            }}
            direction="row"
            spacing={1}
          >
            <Fab
              aria-label="accept changes"
              color="primary"
              onClick={handleAccept}
              size="small"
            >
              <CheckRoundedIcon />
            </Fab>
            <Fab
              aria-label="cancel changes"
              onClick={handleCancel}
              size="small"
            >
              <CloseRoundedIcon />
            </Fab>
          </Stack>
        )}
      </Box>
    );
  }
);
