import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Box, Typography } from '@mui/material';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatDate } from '@/react/utils/date';
import { formatMoney } from '@/utils/money-parser';
import { useRefundStatus } from '@/react/components/refund/utils/use-refund-status';

const Styled = {
  Grid: styled(Grid)(({ theme: { palette, spacing } }) => ({
    alignItems: 'flex-start',
    backgroundColor: palette.neutral[200],
    border: `1px solid ${palette.neutral[300]}`,
    borderRadius: 0,
    color: palette.neutral[800],
    display: 'flex',
    flexDirection: 'column',
    marginBottom: spacing(2),
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
    paddingBottom: spacing('0.5'),
    paddingTop: spacing('0.5'),
    width: 'fit-content',
  })),
};

export const PaymentHeader = () => {
  const { resourceApproval } = useRefundRequest();
  if (!resourceApproval) return null;
  const { mappedStatus } = useRefundStatus(resourceApproval);

  return (
    <>
      {['pendingRefundPaymentStepFive'].includes(mappedStatus) && (
        <Grid item xs={12}>
          <Styled.Grid>
            <Typography variant="body3" fontWeight={400}>
              {`Documentação aprovada em: ${formatDate(resourceApproval?.refund_request?.decided_at)}. O pagamento será feito em até 5 dias úteis.`}
            </Typography>
          </Styled.Grid>
        </Grid>
      )}
      {['paidRefundRequestStepFive'].includes(mappedStatus) && (
        <Grid item xs={12}>
          <Typography
            variant="body3"
            fontWeight={400}
            sx={{
              paddingBottom: 2,
            }}
          >
            {` Pago em: ${formatDate(resourceApproval?.refund_request?.refund?.paid_at)}`}
          </Typography>
        </Grid>
      )}
    </>
  );
};

const PaymentApproved = () => {
  const { resourceApproval } = useRefundRequest();
  if (!resourceApproval) return null;
  return (
    <Box
      sx={({ spacing }) => ({
        paddingLeft: spacing(5),
      })}
    >
      <Typography variant="body3" fontWeight={900}>
        Seu reembolso
      </Typography>
      <Typography
        variant="h5"
        fontWeight={600}
        sx={({ palette }) => ({
          color: palette.success[700],
        })}
      >
        {formatMoney(resourceApproval?.refund_request?.refundable_amount)}
      </Typography>
    </Box>
  );
};

export const PaymentResume: React.FC = () => {
  const { resourceApproval } = useRefundRequest();
  if (!resourceApproval) return null;
  const { mappedStatus } = useRefundStatus(resourceApproval);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {['paidRefundRequestStepFive'].includes(mappedStatus) && (
          <PaymentApproved />
        )}
      </Grid>
    </Grid>
  );
};

export default PaymentResume;
