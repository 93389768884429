import React from 'react';

import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import {
  ActionsMenu,
  ActionsMenuProps,
} from '@/react/components/idp/shared/actions-menu.component';

interface SectionHeaderProps {
  actions?: ActionsMenuProps['actions'];
  children?: React.ReactNode;
  handleBackNavigation?: () => void;
  title?: string;
}

const SectionHeader = ({
  actions,
  children,
  handleBackNavigation,
  title,
}: SectionHeaderProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack alignItems="center" direction="row" gap={1} sx={{ flex: 1 }}>
          {handleBackNavigation && (
            <IconButton
              aria-label="voltar"
              size="small"
              onClick={handleBackNavigation}
            >
              <ArrowBackIosRoundedIcon />
            </IconButton>
          )}
          {title && <Typography variant="h5">{title}</Typography>}
          {children}
        </Stack>
        {actions?.length > 0 && <ActionsMenu actions={actions} />}
      </Box>
      <Divider sx={{ mt: 3 }} />
    </>
  );
};

export { SectionHeader };
