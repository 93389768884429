import { Injectable } from '@angular/core';


import { Api2Service } from './../api2.service';
import { OfferWithDetails } from '@/react/data/catalog/interfaces';

@Injectable({
  providedIn: 'root',
})
export class CoursesService extends Api2Service {
  readonly router: string = 'catalog';

  filters;

  async getById(id: number): Promise<OfferWithDetails> {
    return await this.get(`offer/${id}`);
  }

  async applyCoupon(id: number, code: string) {
    return await this.get(`offer/${id}/coupon/${code}`);
  }
}
