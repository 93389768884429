import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { SubsidyClient } from '@/react/data/subsidy/subsidy-client';
import { useWizard } from '@/react/components/wizard/state/useWizard';
import { useAccordion } from '@/react/components/refund/accordion/state/useAccordion';
import { RefundRequestSteps } from '@/react/components/refund/refund-request/interfaces';
import { WizardStepIds } from '@/react/components/refund/refund-request/description-step/interfaces';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { formatMoney } from '@/utils/money-parser';
import StepActions from '@/react/components/wizard/step-actions.component';
import RefundRequestCreatedDialog from '../../refund-request-created-dialog.component';
('@react/components/refund/refund-request/refund/refund-request-created-dialog.component');
import { useRequestRefundableAmount } from '@/react/data/subsidy/useRequestRefundableAmount';
import { RefundRequestStatus } from '@/react/data/subsidy/interfaces';
import { useNotification } from '@/react/components/notification';
import { openDisplayErrorsIntoToast } from '@/react/components/refund/utils/openDisplayErrorsIntoToast';
import { openToast } from '@/react/components/refund/utils/openToast';
import { DateTime } from 'luxon';

const STATUS_CODE_INTERNAL_SERVER_ERROR = 500;

const RefundableValue = ({
  isValid,
  value,
}: {
  isValid: boolean;
  value: number;
}) => {
  return (
    <Box
      component="span"
      sx={({ palette }) => ({
        color: !isValid ? palette.error.main : 'inherit',
      })}
    >
      {formatMoney(value)}
    </Box>
  );
};

const fieldTranslations: Record<string, string> = {
  supplier_name: 'Nome do Fornecedor',
  supplier_document: 'Documento do Fornecedor',
  holder_name: 'Nome do Titular',
  pix_type: 'Tipo de Conta Bancária',
  receiver_fiscal_document: 'CPF do comprador',
  banking_info: 'Informações Bancárias',
  payment_date: 'Data do Pagamento',
  purchase_date: 'Data da Compra',
  documents_proving_payment: 'Comprovante de pagamento',
  documents_proving_purchase: 'Comprovante de compra',
  payment_amount_cents: 'Valor do Pagamento',
  purchase_amount_cents: 'Valor da Compra',
};

interface FormattedDateProps {
  strDate: string;
}

const formattedDate = ({ strDate }: FormattedDateProps): string => {
  const dateFormatted = DateTime.fromISO(strDate).toUTC().toISO();

  return dateFormatted;
};

const formatCreateRefundRequest = ({ formValues }) => {
  return {
    resource_approval: { uuid: formValues.resource_approval_uuid },
    id: formValues.id,
    refund_request: {
      supplier_type: formValues.supplierType,
      supplier_name: formValues.supplierName,
      supplier_document: formValues.supplierDocument.toString(),
      payment_date: formattedDate({ strDate: formValues.paymentDate }),
      payment_amount: formValues.paymentAmount,
      purchase_date: formattedDate({ strDate: formValues.purchaseDate }),
      purchase_amount: formValues.purchaseAmount,
      receiver_fiscal_document: formValues.documentEmployeer,
      reference_month: formValues?.referenceMonth,
      banking_info: {
        holder_name: formValues?.holderName,
        bank: formValues?.bank,
        agency: formValues?.agency,
        account_number: formValues?.accountNumber,
        pix_key: formValues?.pixKey,
        pix_type: formValues?.pixType,
      },
    },
    receipts: [...formValues.receipts],
  };
};

const initialState = {
  isSubmitting: false,
  resourceApprovalCreated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CREATED':
      return { ...state, resourceApprovalCreated: action.payload };
    case 'SET_SUBMITTING':
      return { ...state, isSubmitting: action.payload };
    default:
      return state;
  }
};

const SendingReceiptsSummary = () => {
  const { actions: wizardActions, values: wizardValues } = useWizard();
  const { actions: accordionActions } = useAccordion();
  const formValues = wizardValues[WizardStepIds.SENDING_RECEIPTS_FORM];
  const { loading, subsidyPolicy, resourceApproval } = useRefundRequest();
  const { toast } = useNotification();

  if (
    (resourceApproval.status === RefundRequestStatus.revision_required ||
      resourceApproval.status === RefundRequestStatus.pending_approval) &&
    resourceApproval?.refund_request.uuid
  ) {
    formValues.id = resourceApproval.refund_request.uuid;
  }

  const { isFetching: isFetchingRefundableAmount, requestRefundableAmount } =
    useRequestRefundableAmount({
      refund_amount_requested: formValues.paymentAmount,
      resource_approval_uuid: formValues.resource_approval_uuid,
      refund_request_uuid: resourceApproval?.refund_request?.uuid,
    });

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const totalValueParsed = parseFloat(formValues.paymentValue ?? '0');
  const refundableValueParsed = parseFloat(
    requestRefundableAmount?.refund_request?.refundable_amount ?? '0'
  );
  const valueMaxRefundable = parseFloat(
    resourceApproval?.requested_refundable_amount ?? '0'
  );
  const refundBalanceAvailable = parseFloat(
    subsidyPolicy?.limits.amount_available ?? '0'
  );
  const valid = !!subsidyPolicy && refundableValueParsed > totalValueParsed;

  const handleCreateOrUpdateRefundRequest = async () => {
    if (!valid) {
      return;
    }

    const requestPayload = formatCreateRefundRequest({
      formValues,
    });

    dispatch({ type: 'SET_SUBMITTING', payload: true });
    dispatch({ type: 'SET_CREATED', payload: false });

    try {
      let resource = null;
      let messageToToast = '';
      if (
        (resourceApproval.status === RefundRequestStatus.revision_required ||
          resourceApproval.status === RefundRequestStatus.pending_approval) &&
        resourceApproval?.refund_request.uuid
      ) {
        resource = await SubsidyClient.updateRefundRequest(requestPayload);
        messageToToast = 'Reembolso atualizado com sucesso';
      } else {
        resource = await SubsidyClient.createRefundRequest(requestPayload);
        messageToToast = 'Reembolso solicitado com sucesso';
      }

      if (!!resource) {
        dispatch({ type: 'SET_SUBMITTING', payload: false });
        dispatch({ type: 'SET_CREATED', payload: true });
      }

      openToast({
        toast,
        message: messageToToast,
        type: 'success',
      });

      accordionActions.close(RefundRequestSteps.INVOICE_ATTACHMENT);
    } catch (error) {
      if (
        error.response?.data &&
        error.response.status !== STATUS_CODE_INTERNAL_SERVER_ERROR
      ) {
        openDisplayErrorsIntoToast({
          fieldTranslations,
          payload: error.response.data,
          toast,
        });
      } else {
        const type = 'error';
        const message = 'Erro ao criar reembolso';
        openToast({
          toast,
          message,
          type,
        });
      }
      dispatch({ type: 'SET_SUBMITTING', payload: false });
    }
  };

  return (
    <>
      <Box
        sx={{
          borderColor: 'neutral.300',
          borderStyle: 'solid',
          borderWidth: 1,
          p: 2,
          mt: 3,
        }}
      >
        <Stack spacing={2}>
          {subsidyPolicy?.limits.amount_limit_per_cycle_cents && (
            <>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 0, sm: 3 }}
              >
                <Box flexGrow={1}>
                  <Typography
                    component="div"
                    sx={(theme) => ({
                      fontWeight: theme.typography.fontWeightBold,
                    })}
                    variant="body3"
                  >
                    Saldo disponível
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component="div"
                    display="flex"
                    justifyContent={{ xs: 'left', sm: 'right' }}
                    variant="body3"
                  >
                    {loading ? (
                      <Skeleton width={60} />
                    ) : (
                      <RefundableValue
                        isValid={valid}
                        value={refundBalanceAvailable}
                      />
                    )}
                  </Typography>
                </Box>
              </Stack>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Custo total do item
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {resourceApproval.sale_price &&
                  formatMoney(resourceApproval.sale_price)}
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Valor pago
              </Typography>
              <Typography component="div" variant="caption">
                De acordo com o comprovante anexado
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {!formValues.paymentAmount ? (
                  <Skeleton width={60} />
                ) : (
                  <RefundableValue
                    isValid={valid}
                    value={formValues.paymentAmount}
                  />
                )}
              </Typography>
            </Box>
          </Stack>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Valor máximo reembolsável
              </Typography>
              <Typography component="div" variant="caption">
                Calculado de acordo com as regras de reembolso da sua política.
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
              >
                {loading ? (
                  <Skeleton width={60} />
                ) : (
                  <RefundableValue isValid={valid} value={valueMaxRefundable} />
                )}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <Box
        sx={{
          borderColor: 'neutral.300',
          borderStyle: 'solid',
          borderWidth: 1,
          borderTop: 0,
          backgroundColor: 'neutral.200',
          marginTop: '0px',
          p: 2,
        }}
      >
        <Stack spacing={2}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 3 }}
          >
            <Box flexGrow={1}>
              <Typography
                component="div"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}
                variant="body3"
              >
                Reembolso final
              </Typography>
              <Typography component="div" variant="caption">
                O cálculo considera os limites do saldo disponível, valor pago e
                valor máximo reembolsável.
              </Typography>
            </Box>
            <Box>
              <Typography
                component="div"
                display="flex"
                justifyContent={{ xs: 'left', sm: 'right' }}
                variant="body3"
                fontWeight={700}
                color="success.700"
              >
                {isFetchingRefundableAmount ? (
                  <Skeleton width={60} />
                ) : (
                  <RefundableValue
                    isValid={valid}
                    value={refundableValueParsed}
                  />
                )}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <StepActions>
        <LoadingButton
          color="primary"
          onClick={() =>
            wizardActions.navigate(WizardStepIds.SENDING_RECEIPTS_FORM)
          }
          variant="text"
        >
          Voltar
        </LoadingButton>
        <LoadingButton
          color="primary"
          disabled={!valid}
          loading={state.isSubmitting}
          onClick={() => handleCreateOrUpdateRefundRequest()}
          variant="contained"
        >
          {(resourceApproval.status === RefundRequestStatus.revision_required ||
            resourceApproval.status === RefundRequestStatus.pending_approval) &&
          resourceApproval?.refund_request.uuid
            ? 'Atualizar'
            : 'Enviar'}
        </LoadingButton>
      </StepActions>
      <RefundRequestCreatedDialog open={state.resourceApprovalCreated} />
    </>
  );
};

export default SendingReceiptsSummary;
