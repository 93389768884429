import { SellableType } from './sellable_type.model';
import { Aula } from './aula.model';
import { Campus } from './campus.model';
import { CursoAluno } from './curso-aluno';
import { CursoDetalhe } from './curso-detalhe.model';
import { Inscricao } from './inscricao.model';
import { Modalidade } from './modalidade.model';
import { Order } from './order.model';
import { User } from './user.model';
import { Coupon } from '../models-new/coupon.model';
import { OfferWithDetails } from '@/react/data/catalog/interfaces';

export class DbCatalogCourse {
  id: number;
  mensalidade: boolean;
  valor_original: number;
  parcelas: number;
  desconto: number;
  nacional: boolean;
  duration: string;
  marketing_description: string;
  modalidade_id: number;
  turno: string;
  modalidade: string;
  ead: boolean;
  cidade: string;
  instituicao_id: number;
  abreviacao: string;
  imagem: string;
  instituicao: string;
  instituicao_logo_url: string;
  instituicao_logo_background_color: string;
  sell_type: number;
  categoria_especifica: string;
  curso: string;
  categoria_id: number;
  categoria: string;
  edupass_indica: boolean;
  updated_at: string;
  price_upon_request: boolean;
  marketable: boolean;
}

export class CursoHorario {
  id: number;
  desconto: number;
  duration: string;
  inicio?: string;
  modalidade: Modalidade;
  modalidadeId: number;
  parcelas: number;
  turno: string;
  vagas?: number;
  valor_original: number;
  carga_horaria?: string;
  marketing_description?: string;
  mensalidade: boolean;
  nacional?: boolean;
  selling_amount?: number;
  has_files: boolean;
  edupass_indica: boolean;
  gift_course: boolean;
  marketable: boolean;
  updated_at?: string;
  price_upon_request: boolean;
  duracao: number;
  duracao_tipo: string;

  aulas?: Aula[];
  curso_alunos?: CursoAluno[];

  campus: Campus;
  campusId?: number;

  orders?: Order[];

  curso_detalhe: CursoDetalhe;
  curso_detalheId?: number;
  curso_detalhe_id?: number;

  inscricoes?: Inscricao[];

  charge_type: string;
  recurrence?: string;
  payment_boleto?: boolean;
  payment_credit_card?: boolean;
  payment_pix?: boolean;
  payment_ticket_super_flex?: boolean;
  certificate?: boolean;
  sale_price?: number;

  InstitutionCheckoutConfig: any;

  static getValorOriginal(instance: CursoHorario) {
    return instance.mensalidade
      ? instance.valor_original * instance.parcelas
      : instance.valor_original;
  }

  static getValorTotal(instance: CursoHorario, user: User) {
    return (
      CursoHorario.getValorOriginal(instance) *
      (1 - CursoHorario.getDesconto(instance, user) / 100)
    );
  }

  static getEdupassPrice(instance: CursoHorario, user: User) {
    return (
      instance.valor_original *
      (1 - CursoHorario.getEdupassDiscount(instance, user) / 100)
    );
  }

  static getEdupassPriceTotal(instance: CursoHorario, user: User) {
    return (
      CursoHorario.getValorOriginal(instance) *
      (1 - CursoHorario.getEdupassDiscount(instance, user) / 100)
    );
  }

  static getValor(instance: CursoHorario, user: User) {
    return (
      instance.valor_original *
      (1 - CursoHorario.getDesconto(instance, user) / 100)
    );
  }

  static getEconomia(instance: CursoHorario, user: User) {
    return (
      CursoHorario.getValorOriginal(instance) -
      CursoHorario.getValorTotal(instance, user)
    );
  }

  static getEdupassEconomy(instance: CursoHorario, user: User) {
    return (
      CursoHorario.getValorOriginal(instance) -
      CursoHorario.getEdupassPriceTotal(instance, user)
    );
  }

  static getDesconto(instance: CursoHorario, user: User) {
    const discount = CursoHorario.getEdupassDiscount(instance, user);

    if (user.isLeroy()) {
      const coursePrice = ((100 - discount) / 100) * instance.valor_original;

      const leroyDiscount = Math.min(300, coursePrice * 0.3);

      const leroyPrice = coursePrice - leroyDiscount;

      return (1 - leroyPrice / instance.valor_original) * 100;
    }
    return discount;
  }

  static getEdupassDiscount(instance: CursoHorario, user: User) {
    const discount =
      user.colaborador.empresa.eduplay_premium &&
      CursoHorario.isEduplay(instance) &&
      !instance.marketable
        ? 100
        : instance.desconto;
    return discount;
  }

  static formattedDiscount(instance: CursoHorario, user: User) {
    const discountString = this.getDesconto(instance, user).toFixed(1);
    const splited = discountString.split('.');
    if (splited[1] === '0') {
      return splited[0];
    }
    return splited.join(',');
  }

  static isMarketable(instance: CursoHorario, user: User) {
    return (
      !!instance.InstitutionCheckoutConfig && this.getValor(instance, user) > 0
    );
  }

  static isEduplay(instance: CursoHorario) {
    return instance.campus.instituicao.instituicao === 'Eduplay';
  }

  static isEstacio(instance: CursoHorario) {
    return instance.campus.instituicao.id === 4;
  }
  static isGraduation(instance: CursoHorario) {
    return (
      instance.curso_detalhe.curso.categoria === 1 ||
      instance.curso_detalhe.curso.categoria === 2
    );
  }

  static calculatedDiscount(instance: CursoHorario, coupon?: Coupon) {
    if (coupon) {
      return coupon.isCumulative
        ? instance.desconto + coupon.discount
        : 100 - ((100 - instance.desconto) * (100 - coupon.discount)) / 100;
    }

    return instance.desconto;
  }

  static getFromDbCatalog(dbCatalogCourse: DbCatalogCourse): CursoHorario {
    const course = new CursoHorario();

    course.id = dbCatalogCourse.id;
    course.mensalidade = dbCatalogCourse.mensalidade;
    course.valor_original = dbCatalogCourse.valor_original;
    course.parcelas = dbCatalogCourse.parcelas;
    course.desconto = dbCatalogCourse.desconto;
    course.nacional = dbCatalogCourse.nacional;
    course.duration = dbCatalogCourse.duration;
    course.marketing_description = dbCatalogCourse.marketing_description;
    course.modalidadeId = dbCatalogCourse.modalidade_id;
    course.turno = dbCatalogCourse.turno;
    course.edupass_indica = dbCatalogCourse.edupass_indica;
    course.updated_at = dbCatalogCourse.updated_at;
    course.price_upon_request = dbCatalogCourse.price_upon_request;
    course.marketable = dbCatalogCourse.marketable;

    course.modalidade = {
      modalidade: dbCatalogCourse.modalidade,
      ead: dbCatalogCourse.ead,
    };

    course.campus = {
      cidade: dbCatalogCourse.cidade,
      instituicao: {
        id: dbCatalogCourse.instituicao_id,
        abreviacao: dbCatalogCourse.abreviacao,
        imagem: dbCatalogCourse.imagem,
        instituicao: dbCatalogCourse.instituicao,
        sell_type: dbCatalogCourse.sell_type,
        logo_url: dbCatalogCourse.instituicao_logo_url,
        logo_background_color:
          dbCatalogCourse.instituicao_logo_background_color,
      },
    };

    course.curso_detalhe = {
      categoria_especifica: dbCatalogCourse.categoria_especifica,

      curso: {
        curso: dbCatalogCourse.curso,
        categoria: dbCatalogCourse.categoria_id,
        categorias: [
          {
            id: dbCatalogCourse.categoria_id,
            categoria: dbCatalogCourse.categoria,
          },
        ],
      },
    };

    return course;
  }

  static fromOfferWithDetails(
    offerWithDetails: OfferWithDetails
  ): CursoHorario {
    const offer = new CursoHorario();

    offer.id = offerWithDetails.id;
    offer.desconto = offerWithDetails.discount_percentage;
    offer.InstitutionCheckoutConfig = offerWithDetails.checkout_config;
    offer.valor_original = offerWithDetails.original_price;

    offer.charge_type = offerWithDetails.sale_type;
    offer.price_upon_request = offerWithDetails.sale_price === null;
    offer.duracao = offerWithDetails.duration.quantity;
    offer.duracao_tipo = offerWithDetails.duration.type;
    offer.duration = `${offerWithDetails.duration.quantity} ${offerWithDetails.duration.type}`;

    offer.mensalidade = offerWithDetails.sale_type === 'tuition';
    offer.modalidade = {
      modalidade: offerWithDetails.course.modality.label,
      ead: offerWithDetails.course.modality.type === 'EAD',
    };

    offer.parcelas = offerWithDetails.max_installments;

    offer.turno = offerWithDetails.period.label;

    offer.curso_detalhe = {
      name: offerWithDetails.course.name,
      curso: {
        curso: offerWithDetails.course.name,
      },
    };
    offer.campus = {
      instituicao: {
        instituicao: offerWithDetails.institution.name,
        logo_background_color:
          offerWithDetails.institution.logo_background_color,
        logo_url: offerWithDetails.institution.logo_url,
        imagem: offerWithDetails.institution.logo_url,
      },
    };

    return offer;
  }
}
