import * as React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/browser';

import {
  Activity,
  ActivityStatuses,
  ActivityStatusLabel,
  PlanStatuses,
} from '@/react/data/idp/interfaces';
import CardLink from '@/react/components/card-link.component';
import { useUpdateActivityStatus } from '@/react/data/idp/useUpdateActivityStatus';

export interface ActivityCardProps {
  activity: Activity;
  planId: string;
  planStatus: PlanStatuses;
  disableClick?: boolean;
}

const statusOptions = Object.entries(ActivityStatusLabel).map(
  ([value, label]) => ({
    value,
    label,
  })
);

const ActivityCard = ({
  planId,
  planStatus,
  activity,
  disableClick,
}: ActivityCardProps) => {
  const { id, title, description, resourceData, status } = activity;
  const { updateActivityStatus } = useUpdateActivityStatus(planId);
  const [activityStatus, setActivityStatus] =
    React.useState<ActivityStatuses>(status);

  const handleChangeStatus = async (event: SelectChangeEvent) => {
    try {
      await updateActivityStatus({
        activityId: id,
        status: event.target.value as ActivityStatuses,
      });
      setActivityStatus(event.target.value as ActivityStatuses);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const shouldShowActivityStatus = planStatus !== PlanStatuses.DRAFT;

  return (
    <Card square sx={{ height: '100%' }}>
      <CardActionArea
        component={CardLink}
        href={`/pdi/planos/${planId}/atividades/${id}`}
        sx={{ height: '100%' }}
        disabled={disableClick}
      >
        <CardContent>
          <Stack spacing={3}>
            {shouldShowActivityStatus && (
              <Box>
                <Select
                  label="Status"
                  onChange={handleChangeStatus}
                  renderValue={(selected) =>
                    `Status: ${ActivityStatusLabel[selected as ActivityStatuses]}`
                  }
                  size="small"
                  value={activityStatus}
                  variant="standard"
                  disabled={planStatus === PlanStatuses.CLOSED}
                >
                  {statusOptions.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            <Stack spacing={1}>
              <Typography variant="h6">{title}</Typography>
              {description && (
                <Typography variant="body3">{description}</Typography>
              )}
            </Stack>
          </Stack>
          {resourceData && (
            <Box paddingTop={20}>
              <Card>
                <CardContent>
                  <Typography fontSize={14}>
                    {resourceData.institutionName}
                  </Typography>
                  <Typography fontSize={16} variant="h5">
                    {resourceData.courseName}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export { ActivityCard };
