import * as React from 'react';

import { useRefundStatus } from '@/react/components/refund/utils/use-refund-status';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';
import { ShowReceiptsInfo } from './refund-request-details/show-receipts-info.component';

export const ShowReceipts = () => {
  const { resourceApproval } = useRefundRequest();
  if (!resourceApproval) return null;
  const { mappedStatus } = useRefundStatus(resourceApproval);

  return (
    <>
      {[
        'waitingForRevisionStepTwo',
        'pendingDocumentationStepThree',
        'waitingForDocumentationRevisionStepFour',
        'declinedRefundRequestStepFour',
        'needsRefundRequestRevisionStepFour',
        'canceledRefundRequestStepThree',
        'pendingRefundPaymentStepFive',
        'paidRefundRequestStepFive',
      ].includes(mappedStatus) && <ShowReceiptsInfo />}
    </>
  );
};
