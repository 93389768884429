import { FalconApiService } from '@/services/falcon-api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SubsidiesService {
  readonly router: string = 'subsidy/api';

  eligible_for_subsidies: boolean = false;

  constructor(private apiService: FalconApiService) {
    this.fetchEligibilityForSubsidies();
  }

  async fetchEligibilityForSubsidies() {
    return new Promise((resolve, reject) => {
      this.apiService
        .get(`${this.router}/policies/check_subsidy_eligibility`)
        .subscribe(
          (response) => {
            this.eligible_for_subsidies =
              response.subsidies_eligibility?.eligible_for_subsidies;
            resolve(response);
          },
          (err) => {
            reject(`Error fetching subsidy eligibility: ${err}`);
          }
        );
    });
  }
}
