import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';

const CardLink = styled(Link)(() => ({
  '&:hover': {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

export default CardLink;
