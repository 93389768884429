import React from 'react';

import { Quiz } from '@/react/components/quiz';

import { GlobalProfileStateEmitter } from './global-profile-emitter';

// remove Modal Dependents when profile quiz is visible
const hotfixRemoveModalDependents = (
  <style
    dangerouslySetInnerHTML={{
      __html: `app-dependents {display: none!important}`,
    }}
  />
);

export function ProfileQuiz() {
  const data = GlobalProfileStateEmitter.useQuizState(({ completed }) => {
    return { completed };
  });

  const { instance: instance } = data;
  const { completed } = data.value || {};

  if (!instance || completed) {
    return null;
  }

  return (
    <>
      {hotfixRemoveModalDependents}
      <Quiz
        variation={'modal'}
        state={instance}
        onFinish={async () => {
          await GlobalProfileStateEmitter.startRecommendationsLongPolling();
        }}
      />
    </>
  );
}
