export enum SupplierType {
  natural_person = 'natural_person',
  legal_person = 'legal_person',
}

export enum ProofOfPurchase {
  nfe = 'Nota Fiscal Eletrônica',
  sale = 'Recibo ',
  ticket = 'Boleto',
  invoice = 'Fatura',
  billet = 'Declaração de matrícula',
}

export enum ProofOfPayment {
  payment = 'Fatura do cartão',
  billing_statement = 'Comprovante de transferência bancária',
}

export const PaymentReceiptTypes = Object.keys(ProofOfPayment);
export const PurchaseReceiptTypes = Object.keys(ProofOfPurchase);

export const OtherReceiptType = 'other';

export const Months = {
  '1': 'Janeiro',
  '2': 'Fevereiro',
  '3': 'Março',
  '4': 'Abril',
  '5': 'Maio',
  '6': 'Junho',
  '7': 'Julho',
  '8': 'Agosto',
  '9': 'Setembro',
  '10': 'Outubro',
  '11': 'Novembro',
  '12': 'Dezembro',
};

export enum TypeOfBankTransfer {
  ted = 'ted',
  pix = 'pix',
}

export enum TypeKeyPix {
  cpf = 'cpf',
  email = 'email',
  phone = 'phone',
  random = 'random',
}

export interface NestedErrorPayload {
  [key: string]: string[] | undefined;
}
export interface ErrorPayload {
  [key: string]: string | string[] | NestedErrorPayload;
}

export interface OpenToastProps {
  toast: (props: { content: React.ReactNode; type: string }) => void;
}

export interface DisplayErrorsIndividuallyProps {
  fieldTranslations?: Record<string, string>;
  payload: ErrorPayload;
  toast: OpenToastProps['toast'];
}
