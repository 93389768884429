import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useScreenSizes } from '@/react/utils/useScreenSizes';

interface ActionProps {
  label: string;
  description: string;
  alignRight?: boolean;
  Dialog: (props: any) => React.JSX.Element;
}

export const Action = ({
  label,
  description,
  alignRight = false,
  Dialog,
}: ActionProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const screenSize = useScreenSizes();

  const positioningProps = alignRight
    ? {
        justifyContent: 'end',
        alignItems: 'center',
        direction: 'row-reverse' as const,
      }
    : { direction: 'column-reverse' as const };

  return (
    <Grid container spacing={2} {...positioningProps}>
      <Grid item xs={12} md="auto">
        <Button
          onClick={() => setIsModalOpen(true)}
          variant="contained"
          fullWidth={!screenSize.desktopSize}
        >
          {label}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className="media-screen"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography
          variant="caption"
          align={screenSize.desktopSize ? 'right' : 'left'}
        >
          {description}
        </Typography>
      </Grid>
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Grid>
  );
};
