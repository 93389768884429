import { OpenToastProps } from '@/react/components/refund/refund-request/invoice-step/interfaces';

export const openToast = ({
  toast,
  message,
  type,
}: OpenToastProps & { message: string; type: string }) => {
  toast({
    content: message,
    type: type,
  });
};
