import React, { ReactNode } from 'react';
import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { Container } from '@mui/material';

export const PageContainer = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const { isSmallScreen, isXLargeScreen } = useScreenSizes();

  const maxWidth = isXLargeScreen ? 'xl' : undefined;

  return (
    <Container fixed={isSmallScreen} maxWidth={maxWidth}>
      {children}
    </Container>
  );
};
