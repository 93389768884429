import { CourseTypes } from '../catalog/interfaces';

export const RefundResourceStatus = {
  approved: 'resource_approval_approved',
  cancelled: 'resource_approval_canceled',
  pending_approval: 'resource_approval_pending_approval',
  rejected: 'resource_approval_rejected',
  revision_required: 'resource_approval_revision_required',
};

export const RefundRequestStatus = {
  approved: 'refund_request_approved',
  cancelled: 'refund_request_canceled',
  paid: 'refund_request_paid',
  pending_approval: 'refund_request_pending_approval',
  rejected: 'refund_request_rejected',
  revision_required: 'refund_request_revision_required',
};

export enum EligibleResourceType {
  THREE_SIXTY = '360',
  CATALOG = 'catalog',
}

export type CalculateCourseRefundableAmountRequest = {
  course: {
    checkout_type: string;
    course_type: string;
    duration: number;
    duration_type: string;
    id: number;
    price: number;
    type: string;
  };
};

export type RefundableValue = { amount: number };

export type SubsidyPolicy = {
  uuid: string;
  name: string;
  description: string;
  ends_at: string;
  ends_cycle_at: string;
  configuration: {
    auto_approval_enabled: boolean;
    course_eligibility_kind: string;
    eligible_resource_type: EligibleResourceType;
    eligible_user_type: string;
    eligible_course_types: CourseTypes[];
  };
  limits: {
    amount_limit_per_purchase_cents: string | null;
    percentage_limit_per_purchase: string | null;
    amount_limit_per_cycle_cents: string | null;
    amount_available: string | null;
  };
};

export type Subsidy = {
  refundable_value: RefundableValue;
};

export type CalculateCourseRefundableAmountResponse = Subsidy;

export type RefundItem = {
  uuid: string;
  supplier_name: string;
  supplier_document: string;
  supplier_type: string;
  decision: string;
  status: string;
  payment_amount: string;
  purchase_amount: string;
  refundable_amount: string;
  payment_date: string;
  purchase_date: string;
  receiver_fiscal_document: string;
  decision_comment: string;
  decided_at: string;
  created_at: string;
  banking_info: {
    bank: string;
    agency: string;
    pix_key: string;
    pix_type: string;
    holder_name: string;
    account_number: string;
  };
  reference_month: string;
  receipts: {
    type: string;
    attachments: {
      url: string;
      content: {
        filename: string;
        byte_size: number;
        content_type: string;
        blob: string;
      };
    }[];
  }[];
  refund: {
    paid_amount: string;
    paid_at: string;
  };
};

export type PaginationData = {
  current_page: number;
  total_pages: number;
  items_per_page: number;
  next_page: number | null;
  previous_page: number | null;
};

export type RequestFilteredRefundsList = {
  status: string;
  page: number;
  per_page: number;
};

export type RequestRefundableAmount = {
  refund_amount_requested: string;
  resource_approval_uuid: string;
  refund_request_uuid: string | null;
};

export type RequestRefundableAmountResponse = {
  refund_request: {
    refundable_amount: string;
  };
  policy_limits: {
    amount_limit_per_purchase_cents: string;
    percentage_limit_per_purchase: string;
    amount_limit_per_cycle_cents: string;
    amount_available: string;
  };
};

export type ResourceApproval = {
  uuid: string;
  employee_id: number;
  requested_refundable_amount: string;
  requested_at: string | null;
  expires_at: string;
  decided_at: string | null;
  decision_comment: string;
  name: string;
  category: string;
  status: string;
  resource: { course: CourseResource };
  refund_request?: RefundItem;
  sale_type: string | null;
  sale_price: string | null;
};

export type RefundableAmountResponse = {
  resource_approvals: ResourceApproval[];
};

export type RequestListResponse = {
  resource_approvals: ResourceApproval[];
  metadata: PaginationData;
};

export type ResourceApprovalResponse = {
  resource_approval: ResourceApproval;
};

export type CourseResource = {
  id: string;
  institution_name: string;
  address: Address;
  course_type: string;
  duration: number;
  duration_type: string;
  link: string;
  modality: string;
  offer_identifier: string | null;
  period: string | null;
  workload: number;
  source: string;
};

export type Address = {
  city: string;
  state: string;
  campus: string;
};
