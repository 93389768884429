import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { SubsidyPolicy } from '@/react/data/subsidy/interfaces';
import { SubsidyClient } from '@/react/data/subsidy/subsidy-client';

const TTL_IN_MS = 1000 * 60; // 1 minute

interface GetAllSubsidyPoliciesResponse {
  policies: SubsidyPolicy[];
}

export interface useSubsidyPolicyReturn {
  error: AxiosError | undefined;
  isFetching: boolean;
  policy: SubsidyPolicy;
}

function findPolicyWithHighestCycleLimit(policies: SubsidyPolicy[]): SubsidyPolicy {
  return policies
    .filter((policy) => policy.limits.amount_limit_per_cycle_cents !== null)
    .reduce((policyWithHigherCycleLimit, currentPolicy) => {
      return currentPolicy.limits.amount_limit_per_cycle_cents > (policyWithHigherCycleLimit?.limits.amount_limit_per_cycle_cents || 0)
        ? currentPolicy
        : policyWithHigherCycleLimit;
    }, null);
}

export function useSubsidyPolicies(): useSubsidyPolicyReturn {
  const result = useQuery<GetAllSubsidyPoliciesResponse>({
    queryKey: ['subsidy-getAllPolicies'],
    queryFn: async () => {
      return SubsidyClient.getAllPolicies();
    },
    staleTime: TTL_IN_MS,
  });

  const error = result.error as AxiosError;
  const policies = result.data?.policies || [];
  // TODO: at the moment we're not supporting multiple policies,
  // so we're just getting the first one. This should change in the future.
  const policyWithCycleLimit = findPolicyWithHighestCycleLimit(policies);
  const policy = policyWithCycleLimit || policies[0] || null;

  return {
    error,
    isFetching: result.isFetching,
    policy,
  } as useSubsidyPolicyReturn;
}
