import * as React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { PlanCard } from '@/react/components/idp/home/components/plan-card.component';
import { PlanCardInProgress } from '@/react/components/idp/home/components/plan-card-in-progress';
import { Plan, PlanStatuses, PlansByStatus } from '@/react/data/idp/interfaces';
import { usePlanActivities } from '@/react/data/idp';

const hasDuration = (plan: Plan) =>
  Boolean(plan.durationInMonths && plan.startedAt && plan.endedAt);

export const ListPlans = ({ plans }: { plans: PlansByStatus }) => {
  const { activities } = usePlanActivities(plans[PlanStatuses.IN_PROGRESS]?.id);

  return (
    <Stack spacing={4} data-testid="listPlans">
      {plans[PlanStatuses.IN_PROGRESS] && (
        <Stack spacing={3}>
          <Typography variant="h5">Em Andamento</Typography>
          <Box>
            <PlanCardInProgress
              activities={activities}
              plan={plans[PlanStatuses.IN_PROGRESS]}
            />
          </Box>
        </Stack>
      )}
      {plans[PlanStatuses.DRAFT] && (
        <Stack spacing={3}>
          <Typography variant="h5">Rascunho</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <PlanCard
                plan={plans[PlanStatuses.DRAFT]}
                data-testid="planItemDraft"
                cardActionLabel="Retomar rascunho"
              />
            </Grid>
          </Grid>
        </Stack>
      )}
      {plans[PlanStatuses.CLOSED].length > 0 && (
        <Stack spacing={3}>
          <Typography variant="h5">Finalizados</Typography>
          <Grid container spacing={4}>
            {plans[PlanStatuses.CLOSED].map((plan: Plan) => (
              <Grid key={plan.id} item xs={12} md={4}>
                <PlanCard plan={plan} data-testid="planItemClosedDates">
                  {hasDuration(plan) && (
                    <Typography variant="body3">
                      {plan.startedAt} - {plan.endedAt}
                    </Typography>
                  )}
                </PlanCard>
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}
    </Stack>
  );
};
