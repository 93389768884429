import { useQuery } from '@tanstack/react-query';
import { IdpClient } from './idp-client';
import { Plan } from './interfaces';

export const queryKey = ['usePlan'];

export function usePlan(planId: string): usePlansType {
  const result = useQuery<Plan>({
    queryKey: [...queryKey, planId],
    queryFn: async () => {
      return IdpClient.showPlan(planId);
    },
    refetchOnMount: false,
  });

  const plan = result.data || null;

  return {
    error: !!result.error,
    isFetching: result.isFetching,
    plan,
  };
}

type usePlansType = {
  error: boolean;
  isFetching: boolean;
  plan: Plan | null;
};
