import { z } from 'zod';

export const updatePlanSchema = z.object({
  title: z.string().max(255).nullable(),
  description: z.string().optional(),
  duration_in_months: z.number().min(1).max(18),
});

export const createActivitySchema = z.object({
  title: z.string().max(255).min(1),
  description: z.string().optional(),
  resource_url: z.string().optional(),
});

export const updateActivitySchema = z.object({
  title: z.string().max(255),
  description: z.string(),
  status: z.string().nullable(),
});
