import { useEffect } from 'react';

export const useSetTitle = (title: string) => {
  useEffect(() => {
    if (title) {
      document.title = `${title} | Galena`;
    }
    return () => {
      document.title = 'Galena';
    };
  }, [title]);
};
