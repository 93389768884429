import React from 'react';
import { ProfileQuiz } from '@/react/components/profile-quiz';
import { HelpMenu } from '@/react/components/help/help-menu.component';

export function ReactGlobalFragment() {
  return (
    <>
      <ProfileQuiz />
      <HelpMenu />
    </>
  );
}
