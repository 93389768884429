import React from 'react';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { SectionHeader } from '@/react/components/idp/shared';

export const PageSkeleton = () => {
  return (
    <Grid container spacing={3} data-testid="page-loading">
      <Grid item xs={12}>
        <SectionHeader>
          <Typography variant="h5" sx={{ width: '100%' }}>
            <Skeleton variant="rounded" />
          </Typography>
        </SectionHeader>
      </Grid>
      <Grid item xs={6} md={4}>
        <Typography variant="body3">
          <Skeleton variant="rounded" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body3">
          <Skeleton variant="rounded" />
        </Typography>
      </Grid>
    </Grid>
  );
};
