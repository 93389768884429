import { useQuery } from '@tanstack/react-query';
import { IdpClient } from './idp-client';
import { Activity } from './interfaces';

export const queryKey = ['usePlanActivity'];

export function usePlanActivity({
  activityId,
  planId,
}: {
  activityId: string;
  planId: string;
}): usePlansType {
  const result = useQuery<Activity>({
    queryKey: [...queryKey, planId, activityId],
    queryFn: async () => {
      return IdpClient.showPlanActivity(planId, activityId);
    },
    refetchOnMount: false,
  });

  const activity = result.data || null;

  return {
    activity,
    error: !!result.error,
    isFetching: result.isFetching,
  };
}

type usePlansType = {
  activity: Activity | null;
  error: boolean;
  isFetching: boolean;
};
