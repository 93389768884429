import { useEffect } from 'react';

interface useClickOutsideProps {
  callback: () => void;
  ref: React.RefObject<HTMLElement>;
}

export const useClickOutside = ({ callback, ref }: useClickOutsideProps) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [callback, ref]);
};
