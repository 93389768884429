import React from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  Link,
  Skeleton,
  Stack,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSubsidyPolicies } from '@/react/data/subsidy/useSubsidyPolicies';
import { formatMoney } from '@/react/utils/money';
import { EmptyPolicies } from './empty-policies.component';
import { formatDate } from '@/react/utils/date';

export const RefundPolicies = () => {
  const { isFetching: isFetchingPolicies, policy } = useSubsidyPolicies();

  if (!isFetchingPolicies && !policy) {
    return <EmptyPolicies />;
  }
  const availableBalance = policy?.limits.amount_available;

  return (
    <Container component="div">
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        my={2}
        gap={1}
      >
        <Grid item xs={12}>
          <Stack direction="row" gap={1}>
            <Link
              href="/meus-reembolsos"
              color="neutral.800"
              sx={({ palette }) => ({
                ':hover': {
                  color: palette.neutral[800],
                },
              })}
            >
              <ArrowBackIosIcon fontSize="small" />
            </Link>
            <Typography variant="h5">Políticas</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} gap={1}>
          <Typography color="neutral.800" variant="body3">
            Confira as políticas disponíveis para solicitar o seu reembolso, no
            caso de dúvidas procure o seu RH.
          </Typography>
        </Grid>
      </Grid>
      <>
        <Box
          height={'100%'}
          width={'100%'}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2}
          my={2}
          sx={({ palette }) => ({
            minHeight: 156,
            border: `1px solid ${palette.neutral[300]}`,
            backgroundColor: `${palette.neutral[50]}`,
          })}
        >
          {isFetchingPolicies && (
            <Stack direction={'column'} gap={2}>
              <Skeleton variant="text" width={80} height={20} />
              <Stack direction={'row'} gap={2}>
                <Skeleton variant="text" width={50} height={20} />
                <Skeleton variant="text" width={200} height={20} />
              </Stack>
              <Stack direction={'row'} gap={2}>
                <Skeleton variant="text" width={50} height={20} />
                <Skeleton variant="text" width={200} height={20} />
              </Stack>
              <Stack direction={'row'} gap={2}>
                <Skeleton variant="text" width={50} height={20} />
                <Skeleton variant="text" width={200} height={20} />
              </Stack>
            </Stack>
          )}
          {!isFetchingPolicies && policy && (
            <Grid container gap={2}>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary.900">
                  {policy.name}
                </Typography>
              </Grid>
              {!!policy.limits.amount_limit_per_cycle_cents && (
                <>
                  <Grid container alignItems={'center'} rowGap={1}>
                    <Grid item xs={4} md={2}>
                      <Typography
                        variant="body3"
                        fontWeight={600}
                        data-testid="refund-balance-label"
                      >
                        Saldo disponível
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={10}>
                      <Typography
                        variant="h6"
                        color="success.700"
                        data-testid="refund-balance-value"
                      >
                        {formatMoney(Number(availableBalance))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems={'center'} rowGap={1}>
                    <Grid item xs={4} md={2}>
                      <Typography
                        variant="body3"
                        fontWeight={600}
                        data-testid="limit-per-cycle-label"
                      >
                        Ciclo do saldo
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={10}>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          component="span"
                          variant="body3"
                          color="success.700"
                          data-testid="limit-per-cycle-value"
                        >
                          {formatMoney(
                            Number(policy.limits.amount_limit_per_cycle_cents)
                          )}
                        </Typography>
                        <Typography variant="body3">por semestre</Typography>
                      </Stack>
                      <Typography variant="body3">
                        Com renovação no dia {formatDate(policy?.ends_cycle_at)}
                        . O saldo não é cumulativo. O saldo não usado até o dia
                        da renovação é perdido.
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
              {policy.limits.amount_limit_per_purchase_cents !== null &&
                policy.limits.percentage_limit_per_purchase !== null && (
                  <Grid container alignItems={'center'} rowGap={1}>
                    <Grid item xs={4} md={2}>
                      <Typography
                        variant="body3"
                        fontWeight={600}
                        data-testid="percentage-and-limit-per-purchase-label"
                      >
                        Limite por compra
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={10}>
                      <Typography variant="body3">
                        <Typography
                          component="span"
                          variant="body3"
                          color="success.700"
                          data-testid="percentage-value"
                        >
                          {parseInt(
                            policy.limits.percentage_limit_per_purchase
                          ) + '%'}
                        </Typography>{' '}
                        até{' '}
                        <Typography
                          component="span"
                          variant="body3"
                          color="success.700"
                          data-testid="amount-limit-per-purchase-value"
                        >
                          {formatMoney(
                            Number(
                              policy.limits.amount_limit_per_purchase_cents
                            )
                          )}
                        </Typography>{' '}
                        por compra
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              <Grid container alignItems={'center'} rowGap={1}>
                <Grid item xs={4} md={2}>
                  <Typography variant="body3" fontWeight={600}>
                    Recursos Elegíveis
                  </Typography>
                </Grid>
                {policy.configuration.eligible_resource_type === '360' ? (
                  <Grid item xs={8} md={10}>
                    <Typography variant="body3" data-testid="all-items">
                      <Typography
                        component="span"
                        variant="body3"
                        fontWeight={600}
                      >
                        Itens na plataforma Galena:
                      </Typography>{' '}
                      Todos
                    </Typography>
                    <Typography
                      variant="body3"
                      data-testid="courses-events-workshops"
                    >
                      <Typography
                        component="span"
                        variant="body3"
                        fontWeight={600}
                      >
                        Itens na plataforma Galena:
                      </Typography>{' '}
                      Cursos, Eventos e palestras.
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={8} md={10}>
                    <Typography variant="body3" data-testid="only-all-items">
                      <Typography
                        component="span"
                        variant="body3"
                        fontWeight={600}
                      >
                        Itens na plataforma Galena:
                      </Typography>{' '}
                      Todos
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container alignItems={'center'} rowGap={1}>
                <Grid item xs={4} md={2}>
                  <Typography variant="body3" fontWeight={600}>
                    Descrição
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10}>
                  <Typography variant="body3">{policy.description}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </>
    </Container>
  );
};

export default RefundPolicies;
