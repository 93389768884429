import React, { useState, useEffect } from 'react';
import { ActivityCard } from '@/react/components/idp/shared/activity-card.component';
import { Box, Button, Grid, Skeleton } from '@mui/material';
import Plan from '@/react/data/idp/plans/plan.model';
import { PlanStatuses } from '@/react/data/idp/plans';
import Typography from '@mui/material/Typography';
import { Activity } from '@/react/data/idp/activities';
import Switch from '@mui/material/Switch';
import { ActivitiesReorder } from './activities-reorder';

enum ComponentStates {
  EMPTY = 'EMPTY',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  NULL = 'NULL',
  REORDERING = 'REORDERING',
}

interface ActivitiesListProps {
  activities: Activity[];
  loading?: boolean;
  plan: Plan;
}

const handleNewActivity = (planId: string) => {
  window.location.href = `/pdi/planos/${planId}/atividades/nova`;
};

const ActivitiesActions = ({
  plan,
  isReorderable,
  onChange,
}: {
  plan: Plan;
  isReorderable: boolean;
  onChange: () => void;
}) => {
  return (
    <>
      <Grid item className="media-screen">
        <Button onClick={() => handleNewActivity(plan.id)} variant="text">
          Adicionar atividade
        </Button>
      </Grid>
      {isReorderable && (
        <Grid
          item
          className="media-screen"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography
            color="primary"
            variant="body3"
            fontWeight="bold"
            padding="6px"
            lineHeight="unset"
          >
            Reordenar
          </Typography>
          <Switch onChange={onChange} />
        </Grid>
      )}
    </>
  );
};

export const ActivitiesList = ({
  activities,
  loading = false,
  plan,
}: ActivitiesListProps) => {
  const [componentState, setComponentState] = useState<ComponentStates>(
    ComponentStates.NULL
  );

  const enableReorder = () =>
    setComponentState((currentComponentState) =>
      currentComponentState === ComponentStates.REORDERING
        ? ComponentStates.LOADED
        : ComponentStates.REORDERING
    );

  const renderState = {
    [ComponentStates.LOADING]: (
      <Skeleton
        data-testid="loading-activities"
        height={130}
        variant="rounded"
      />
    ),
    [ComponentStates.LOADED]: (
      <>
        {activities.map((activity) => (
          <ActivityCard
            key={activity.id}
            planId={plan.id}
            planStatus={plan.status}
            activity={activity}
          />
        ))}
      </>
    ),
    [ComponentStates.EMPTY]: (
      <Typography variant="body3">
        Adicione as atividades que você realizará para atingir seus objetivos
        com esse plano. Ex.: Fazer um curso de liderança, Procurar um mentor,
        Escrever um artigo, e etc...
      </Typography>
    ),
    [ComponentStates.REORDERING]: (
      <ActivitiesReorder
        planId={plan.id}
        planStatus={plan.status}
        activities={activities}
      />
    ),
  };

  useEffect(() => {
    if (loading) {
      return setComponentState(ComponentStates.LOADING);
    }

    if (activities?.length > 0) {
      setComponentState(ComponentStates.LOADED);
    }
    if (activities?.length === 0) {
      setComponentState(
        plan.status !== PlanStatuses.CLOSED
          ? ComponentStates.EMPTY
          : ComponentStates.NULL
      );
    }
  }, [loading, activities]);

  return componentState === ComponentStates.NULL ? null : (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography variant="h6">Atividades</Typography>
        </Box>

        {plan.status !== PlanStatuses.CLOSED && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              margin: '6px 0',
            }}
          >
            <ActivitiesActions
              plan={plan}
              onChange={enableReorder}
              isReorderable={activities.length > 1}
            />
          </Box>
        )}
      </Box>
      {renderState[componentState]}
    </Box>
  );
};
