import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { Plan, PlanStatuses } from '@/react/data/idp/interfaces';

type PlanCard = {
  plan: Plan;
  cardActionLabel?: string;
  children?: ReactNode;
};

export const createPlanRoute = (plan: Plan): string => {
  return `/pdi/planos/${plan.id}`;
};

const defaultActionLabel = 'Ver Plano';

export const PlanCard: FC<PlanCard> = ({
  plan,
  cardActionLabel = defaultActionLabel,
  children,
}) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <CardHeader
        title={
          <Typography variant="h5">
            {plan.title || 'Plano sem título'}
          </Typography>
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <CardContent>
          <Stack spacing={0}>
            <Typography variant="body3">
              {(plan.durationInMonths && `${plan.durationInMonths} meses`) ||
                'Sem duração'}
            </Typography>
            {children}
          </Stack>
        </CardContent>
        <CardActions sx={{ marginTop: 'auto' }}>
          <Button href={createPlanRoute(plan)} variant="text">
            {cardActionLabel}
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};
