import * as React from 'react';
import { getSvg } from '@/react/utils/assets';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

export const NoAvailableBalance = () => {
  const handleBack = () => {
    window.history.back();
  }

  return (
    <Grid
      container
    >
      <Grid item xs={12}>
        <img src={getSvg('no_available_balance.svg')} role="img" width="128" height="128" />
      </Grid>

      <Grid item xs={12}>
        <Typography
          color="primary.200"
          variant={'body1'}
          fontWeight={600}
          sx={{ marginTop: '24px' }}
        >
          Você não tem saldo disponível para pedir reembolso.
        </Typography>
        <Typography
          color="neutral.800"
          variant={'body3'}
        > 
          Isso pode ser porque você já gastou todo o saldo ou porque ele ainda não foi renovado. Para conferir o seu saldo e histórico de uso,{' '}
            <Link
              color='neutral.800'
              href='/meus-reembolsos'
              sx={{
                textDecoration: 'underline',
                fontWeight: '500',
                '&:hover': {
                  color: 'primary.main'
                }
              }}
            >
              clique aqui
            </Link>.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ marginTop: '24px' }}/>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            marginTop: '24px',
            width: {xs: '100%', md: '152px'},
            height: '48px'
          }}
          onClick={handleBack}
        >
          Voltar
        </Button>
      </Grid>
    </Grid>
  );
}
