import React, { useState, useCallback } from 'react';
import { ActivityCard } from '@/react/components/idp/shared/activity-card.component';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Activity } from '@/react/data/idp/activities';
import { useReorderActivities } from '@/react/data/idp/useReorderActivities';
import { DragHandle as MaterialDragHandle } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { PlanStatuses } from '@/react/data/idp/plans';

const reorder = (
  list: Activity[],
  startIndex: number,
  endIndex: number
): Activity[] => {
  const reorderedList = list;
  const [removed] = reorderedList.splice(startIndex, 1);
  reorderedList.splice(endIndex, 0, removed);

  return reorderedList;
};

const DragHandle = styled(MaterialDragHandle)`
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  margin: auto 0;
`;

interface ActivitiesReorderProps {
  activities: Activity[];
  planId: string;
  planStatus: PlanStatuses;
}

export const ActivitiesReorder = ({
  activities,
  planId,
  planStatus,
}: ActivitiesReorderProps) => {
  const { reorderActivities } = useReorderActivities(planId);
  const [items, setItems] = useState(activities);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const reorderedItems = reorder(
        items,
        result.source.index,
        result.destination.index
      );

      setItems(reorderedItems);
      reorderActivities(reorderedItems);
    },
    [items]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((activity, index) => (
              <Draggable
                key={activity.id}
                draggableId={activity.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div style={{ position: 'relative' }}>
                      <ActivityCard
                        planId={planId}
                        planStatus={planStatus}
                        activity={activity}
                        disableClick={true}
                      />
                      <DragHandle />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
