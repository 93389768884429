import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const status = {
  active: 'active',
  done: 'done',
  remaining: 'remaining',
};

const labels = {
  active: 'Em andamento',
  done: 'Feito',
  remaining: 'A fazer',
};

interface ActivityProgressSlot {
  percentage?: number;
  quantity: number;
}

export interface ActivityProgressProps {
  active: ActivityProgressSlot;
  done: ActivityProgressSlot;
  remaining: ActivityProgressSlot;
}

const ActivityProgressSlot = ({ color, id, label, percentage }) => {
  return (
    <Tooltip title={label} placement="top">
      <Box
        component="span"
        data-testid={`activity-progress-${id}`}
        sx={{
          backgroundColor: color,
          overflow: 'hidden',
          textIndent: '100%',
          whiteSpace: 'nowrap',
          width: `${percentage}%`,
        }}
      >
        {percentage}
      </Box>
    </Tooltip>
  );
};

const ActivityProgressLegend = ({ color, label }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Box
        sx={(theme) => ({
          backgroundColor: color,
          height: theme.spacing(2),
          width: theme.spacing(2),
          borderRadius: theme.spacing(2),
        })}
      />
      <Typography
        variant="body3"
        sx={(theme) => ({
          fontWeight: theme.typography.fontWeightBold,
        })}
      >
        {label}
      </Typography>
    </Box>
  );
};

export const ActivityProgress = ({ data }: { data: ActivityProgressProps }) => {
  const theme = useTheme();

  const colors = {
    [status.done]: theme.palette.success.dark,
    [status.active]: theme.palette.success.light,
    [status.remaining]: theme.palette.neutral[400],
  };

  const formatDataSlot = ({
    item,
    key,
  }: {
    item: ActivityProgressSlot;
    key: keyof typeof status;
  }) => {
    const { percentage = 0, quantity } = item;
    const color = colors[key];
    const label = `${labels[key]}: ${quantity} (${percentage.toFixed(0)}%)`;

    return {
      color,
      label,
      percentage,
      quantity,
    };
  };

  const renderData = (data: ActivityProgressProps) => {
    const items = Object.keys(data).map((key) => {
      const item = data[key];
      const { color, label, percentage } = formatDataSlot({
        item,
        key,
      } as {
        item: ActivityProgressSlot;
        key: keyof typeof status;
      });

      return (
        <ActivityProgressSlot
          color={color}
          id={key}
          key={key}
          label={label}
          percentage={percentage}
        />
      );
    });

    return items;
  };

  const renderLegend = (data: ActivityProgressProps) => {
    const items = Object.keys(data).map((key) => {
      const item = data[key];
      const { color, label } = formatDataSlot({
        item,
        key,
      } as {
        item: ActivityProgressSlot;
        key: keyof typeof status;
      });

      return <ActivityProgressLegend color={color} key={key} label={label} />;
    });

    return items;
  };

  return (
    <Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.neutral[400],
          display: 'flex',
          flex: 1,
          height: theme.spacing(1),
          width: '100%',
        })}
      >
        {renderData(data)}
      </Box>
      <Box
        mt={2}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          justifyContent: 'flex-start',
        }}
      >
        {renderLegend(data)}
      </Box>
    </Box>
  );
};
