import React from 'react';
import { FormControl, FormLabel, Typography } from '@mui/material';

import { Plan, PlanStatuses } from '@/react/data/idp/interfaces';

interface DateDisplayProps {
  title: string;
  date: string;
}

const DateDisplay = ({ title, date }: DateDisplayProps) => (
  <>
    <FormControl fullWidth>
      <FormLabel>
        <Typography variant="body3" fontWeight="bold">
          {title}
        </Typography>
      </FormLabel>
      <Typography variant="body3">{date}</Typography>
    </FormControl>
  </>
);

export const StartedAt = ({ plan }: { plan: Plan }) =>
  plan.status === PlanStatuses.DRAFT ? null : (
    <DateDisplay title="Iniciou em" date={plan.startedAt} />
  );

export const EstimatedEndsAt = ({ plan }: { plan: Plan }) =>
  plan.status !== PlanStatuses.IN_PROGRESS ? null : (
    <DateDisplay title="Fim (calculado/estimado)" date={plan.estimatedEndsAt} />
  );

export const EndedAt = ({ plan }: { plan: Plan }) =>
  plan.status !== PlanStatuses.CLOSED ? null : (
    <DateDisplay title="Fim (data da conclusão)" date={plan.endedAt} />
  );
