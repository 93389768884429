import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useEmblaCarousel from 'embla-carousel-react';

import baseCarouselStyle from '@/react/components/embla-carousel/base-style';
import { Carousel } from '@/react/components/embla-carousel';
import { PropsWithChildren } from 'react';

const DEFAULT_HEIGHT_IN_PX = 348;

const BaseLoadingCard = ({ children }: PropsWithChildren) => (
  <Box sx={{ display: 'flex', height: '100%' }}>
    <Card
      variant="outlined"
      sx={(theme) => ({
        backgroundColor: children
          ? theme.palette.neutral[50]
          : theme.palette.neutral[300],

        DEFAULT_HEIGHT_IN_PX,
        width: '100%',
      })}
      elevation={0}
    >
      <CardContent sx={{ padding: 2 }}>{children}</CardContent>
    </Card>
  </Box>
);

const LoadingCard = () => (
  <BaseLoadingCard>
    <Stack spacing={2}>
      <CircularProgress color="primary" />
      <Typography variant="h6">
        Estamos personalizando as suas recomendações
      </Typography>
      <Typography variant="body3">
        Suas respostas estão sendo processadas e logo teremos recomendações para
        você. Isso pode demorar alguns minutos.
      </Typography>
    </Stack>
  </BaseLoadingCard>
);

const Styled = {
  Carousel: styled(Carousel)(({ theme }) => ({ ...baseCarouselStyle({ theme }) })),
};

export const RecommendationCarouselLoading = () => {
  const emblaOptions = {
    active: false,
  };

  const instance = useEmblaCarousel(emblaOptions);

  const renderSlidesComponents = () => {
    const content = [<LoadingCard />];
    const blankCards = Array.from({ length: 10 }, () => <BaseLoadingCard />);

    content.push(...blankCards);

    return content;
  };

  const slidesComponents = renderSlidesComponents();

  return (
    <Styled.Carousel instance={instance} slidesComponents={slidesComponents} />
  );
};
