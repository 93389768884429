import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IdpClient } from './idp-client';
import { ActivityStatuses } from './interfaces';
import { queryKey as usePlanActivityQueryKey } from '@/react/data/idp/usePlanActivity';
import { queryKey as usePlanActivitiesQueryKey } from '@/react/data/idp/usePlanActivities';

export function useUpdateActivityStatus(planId: string) {
  const queryClient = useQueryClient();
  const { mutateAsync: updateActivityStatus } = useMutation({
    mutationFn: async ({
      activityId,
      status,
    }: {
      activityId: string;
      status: ActivityStatuses;
    }) => {
      return IdpClient.updateActivityStatus({ planId, activityId, status });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: usePlanActivitiesQueryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: usePlanActivityQueryKey,
      });
    },
  });

  return {
    updateActivityStatus,
  };
}
