import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IdpClient } from '@/react/data/idp/idp-client';
import * as Sentry from '@sentry/browser';

import { SectionHeader } from '@/react/components/idp/shared';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createActivitySchema } from '@/react/data/idp/schemas';
import {
  CreateActivityInterface,
  PlanStatuses,
} from '@/react/data/idp/interfaces';

const navigateBack = (planId: string) => {
  window.location.href = `/pdi/planos/${planId}`;
};

export const CreateActivity = ({ planId }) => {
  const [plan, setPlan] = useState(null);

  const fetchPlan = async () => {
    try {
      const fetchedPlan = await IdpClient.showPlan(planId);
      if (fetchedPlan.status === PlanStatuses.CLOSED) {
        window.location.href = `/pdi/planos/${planId}`;
      }
      setPlan(fetchedPlan);
    } catch (error) {
      Sentry.captureException(error);
      window.location.href = '/pdi/planos';
    }
  };

  const { control, handleSubmit } = useForm({
    resolver: zodResolver(createActivitySchema),
    defaultValues: {
      title: '',
      description: '',
      resource_url: '',
    },
  });

  const handleActivityForm = handleSubmit(
    async (data: CreateActivityInterface) => {
      try {
        await IdpClient.createActivity(planId, data);
        navigateBack(planId);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    (errors) => {
      console.error('Errors', errors);
    }
  );

  useEffect(() => {
    fetchPlan();
  }, []);

  return (
    plan && (
      <Container fixed maxWidth="md">
        <Box sx={{ py: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionHeader
                handleBackNavigation={() => navigateBack(plan.id)}
                title="Nova atividade"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="title"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <FormControl fullWidth>
                      <FormLabel htmlFor="title">Nome da atividade</FormLabel>
                      <TextField
                        {...field}
                        id="title"
                        placeholder="[Título da atividade pré-preenchida]"
                        variant="outlined"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth>
                      <FormLabel htmlFor="description">
                        Descrição (opcional)
                      </FormLabel>
                      <TextField
                        {...field}
                        id="description"
                        placeholder="[Descrição da atividade]"
                        variant="outlined"
                        multiline
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="resource_url"
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth>
                      <FormLabel htmlFor="resource_url">
                        Link do curso (opcional)
                      </FormLabel>
                      <TextField
                        {...field}
                        id="resource_url"
                        placeholder="Link do curso"
                        variant="outlined"
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Button onClick={handleActivityForm} variant="contained">
                Adicionar atividade
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  );
};
