import React from 'react';

import { IdpClient } from '@/react/data/idp/idp-client';
import { useNotification } from '@/react/components/notification';
import * as Sentry from '@sentry/browser';
import { IdpConfirmDialog } from '@/react/components/idp/dialogs';
import { Plan } from '@/react/data/idp/interfaces';

export interface ConfirmStartPlanDialogProps {
  open: boolean;
  onClose: () => void;
  plan: Partial<Plan>;
}

type HandleRestartPlanPros = {
  plan: Partial<Plan>;
  onError: (error: string) => void;
  onSuccess: () => void;
};

const handleRestartPlan = async ({
  plan,
  onSuccess,
  onError,
}: HandleRestartPlanPros) => {
  try {
    await IdpClient.startPlan(plan.id);
    onSuccess();
  } catch (error) {
    let errorMessage: string;

    if (error.response.status !== 422) {
      Sentry.captureException(error);
      errorMessage =
        'Ocorreu um erro ao iniciar o plano. Tente novamente mais tarde';
    } else {
      const offenderPlan: Plan =
        error.response?.data?.errors?.offender_plan?.title ?? 'em andamento';
      errorMessage = `Só é possível ter um plano em andamento por vez. Para iniciar esse, finalize o plano ${offenderPlan}.`;
    }

    onError(errorMessage);
  }
};

export const ConfirmRestartPlanDialog = ({
  open,
  onClose,
  plan,
}: ConfirmStartPlanDialogProps) => {
  const { toast } = useNotification();

  const listeners = {
    onError: (error: string) => {
      toast({
        content: error,
        type: 'error',
      });
      onClose();
    },
    onSuccess: () => {
      window.location.reload();
    },
  };

  return (
    <IdpConfirmDialog
      open={open}
      onClose={onClose}
      onConfirm={() => {
        handleRestartPlan({ plan, ...listeners });
      }}
      title="Deseja retomar este plano?"
      subtitle=""
      description="Depois de iniciado, você vai poder acompanhar e atualizar o progresso das atividades. Lembre-se: O plano terá uma nova data de início quando o plano volta para “Em andamento.”"
      actionLabels={{
        confirm: 'Retomar plano',
        cancel: 'Voltar',
      }}
    />
  );
};
