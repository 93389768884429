import {
  ActivityResponseType,
  ActivityStatuses,
} from '@/react/data/idp/activities/interfaces';
import { Resource } from '@/react/data/idp/resource';

export default class Activity {
  id: string;
  planId: string;
  title: string;
  description: string;
  status: ActivityStatuses;
  resourceUrl?: string;
  resourceId?: number;
  resourceData?: Resource;

  constructor(args: Activity) {
    const {
      id,
      planId,
      title,
      description,
      status,
      resourceUrl,
      resourceId,
      resourceData,
    } = args;

    this.id = id;
    this.planId = planId;
    this.title = title;
    this.description = description;
    this.resourceUrl = resourceUrl;
    this.resourceId = resourceId;
    this.status = status;
    this.resourceData = resourceData ? new Resource(resourceData) : undefined;
  }

  static fromServerPayload(payload: ActivityResponseType): Activity {
    return new Activity({
      id: payload.id,
      planId: payload.plan_id,
      title: payload.title,
      description: payload.description,
      resourceUrl: payload.resource_url,
      resourceId: payload.resource_id,
      status: payload.status,
      resourceData: payload.resource_data
        ? Resource.fromServerPayload(payload.resource_data)
        : undefined,
    });
  }

  static getAmountOfActivitiesByStatus(
    activities: Activity[]
  ): Record<ActivityStatuses, { quantity: number; percentage: number }> {
    const totalActivities = activities.length;

    const initialStatusCounts: Record<
      ActivityStatuses,
      { quantity: number; percentage: number }
    > = {
      [ActivityStatuses.IN_PROGRESS]: { quantity: 0, percentage: 0 },
      [ActivityStatuses.COMPLETED]: { quantity: 0, percentage: 0 },
      [ActivityStatuses.PENDING]: { quantity: 0, percentage: 0 },
    };

    const statusCounts = activities.reduce((acc, activity) => {
      if (!acc[activity.status]) {
        acc[activity.status] = { quantity: 0, percentage: 0 };
      }
      acc[activity.status].quantity++;
      return acc;
    }, initialStatusCounts);

    for (const status in statusCounts) {
      statusCounts[status].percentage =
        (statusCounts[status].quantity / totalActivities) * 100;
    }

    return statusCounts;
  }
}
