import * as React from 'react';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import useEmblaCarousel from 'embla-carousel-react';

import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';

import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { Carousel } from '@/react/components/embla-carousel';
import CourseCard from '@/react/components/catalog/course-card.component';
import baseCarouselStyle from '@/react/components/embla-carousel/base-style';

const Styled = {
  Carousel: styled(Carousel)(({ theme }) => {
    return {
      '& .embla__pagination': {
        position: 'relative',
        bottom: '0',
        marginTop: theme.spacing(2),
      },
      '& .embla__viewport': {
        position: 'relative',

        '.embla__navigation-button': {
          display: 'none',
        },

        ':hover': {
          '.embla__navigation-button': {
            display: 'flex',
          },
        },

        '&:not(.is-touched)': {
          '.embla__navigation-prev-button ': {
            display: 'none',
          },
        },
      },
      ...baseCarouselStyle({ theme }),
    };
  }),
};

const gesturePlugin = WheelGesturesPlugin();

export const RecommendationCarousel = ({ slides }) => {
  if (!slides?.length) {
    return null;
  }
  const theme = useTheme();
  const { isSmallScreen } = useScreenSizes();

  const emblaCarousel = useEmblaCarousel(
    {
      align: 'start',
      dragFree: true,
      loop: true,
      slidesToScroll: 1,
      breakpoints: {
        [`(min-width: ${theme.breakpoints.values.md}px)`]: {
          slidesToScroll: 2,
        },
        [`(min-width: ${theme.breakpoints.values.lg}px)`]: {
          slidesToScroll: 3,
        },
      },
    },
    [gesturePlugin]
  );

  const [_, emblaApi] = emblaCarousel;

  const setTouchedClass = React.useCallback(() => {
    if (!emblaApi) return;

    const carouselElement = emblaApi.rootNode();
    carouselElement.classList.add('is-touched');
  }, [emblaApi]);

  React.useEffect(() => {
    if (!emblaApi) return;

    emblaApi.on('select', setTouchedClass);
  }, [emblaApi]);

  const slidesComponents = slides.map((slide, idx) => {
    return (
      <CourseCard
        data={slide}
        ignoreShowcaseMargin
        key={slide.id + idx}
        urlParams={{ utm_campaign: 'recommendation_carousel' }}
      />
    );
  });

  return (
    <Styled.Carousel
      instance={emblaCarousel}
      navigation={!isSmallScreen}
      slidesComponents={slidesComponents}
    />
  );
};
