import React from 'react';

import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';

export interface ActionsMenuProps {
  actions: {
    label: string;
    onClick: () => void;
    Icon: React.ReactNode;
  }[];
}

export const ActionsMenu = ({ actions }: ActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-label="mais opções"
        id="actions-button"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'actions-button',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        className="media-screen"
      >
        {actions.map(({ onClick, label, Icon }) => (
          <MenuItem key={label} onClick={onClick}>
            <ListItemIcon>{Icon}</ListItemIcon>
            <Typography variant="body3">{label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
