import * as React from 'react';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/browser';

import { IdpClient } from '@/react/data/idp/idp-client';
import { ListPlans } from '@/react/components/idp/home/components/list-plans.component';
import { usePlans } from '@/react/data/idp';
import { PlanStatuses } from '@/react/data/idp/plans';
import { PageContainer, SectionHeader } from '@/react/components/idp/shared';
import { PageSkeleton } from '@/react/components/idp/shared';
import { useSetTitle } from '@/react/hooks/useSetTitle';

import { NewPlanDisabledDialog } from '@/react/components/idp/dialogs';

export const PlansHome = () => {
  useSetTitle('Planos de desenvolvimento');
  const { isFetching: fetchingPlans, plans, plansCount } = usePlans();
  const [openModal, setOpenModal] = React.useState(false);

  const disableAction =
    !!plans[PlanStatuses.DRAFT] || !!plans[PlanStatuses.IN_PROGRESS];

  const createNewPlan = async () => {
    if (disableAction) {
      return setOpenModal(true);
    }

    try {
      const plan = await IdpClient.createPlan({ title: null });
      window.location.href = `/pdi/planos/${plan.id}`;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <PageContainer>
      <Box sx={{ py: 3 }}>
        {fetchingPlans ? (
          <PageSkeleton />
        ) : (
          <Grid container spacing={3}>
            <Grid xs={12} item>
              <SectionHeader>
                <Stack spacing={2}>
                  <Typography variant="h5">Plano de desenvolvimento</Typography>
                  <Typography variant="body3">
                    Acelere seu crescimento profissional com um plano de
                    desenvolvimento personalizado para alcançar seus objetivos.
                    Crie o seu plano ou use as recomendações da nossa
                    inteligência artificial. <a href="/#">Veja como funciona</a>
                  </Typography>
                </Stack>
              </SectionHeader>
            </Grid>
            <Grid xs={12} md={3} lg={2} item>
              <Button
                fullWidth
                onClick={createNewPlan}
                variant="contained"
                size="large"
              >
                Novo plano
              </Button>
            </Grid>
            <Grid xs={12} item>
              {plansCount > 0 && <ListPlans plans={plans} />}
            </Grid>
          </Grid>
        )}
        <NewPlanDisabledDialog
          onClose={() => setOpenModal(false)}
          open={openModal}
        />
      </Box>
    </PageContainer>
  );
};
