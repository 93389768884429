import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import type { ComponentProps } from 'react';

import { ZoomLMSService } from '@/services/zoom-lms.service';
import { LayoutService } from 'src/app/services/layout.service';
import { PlansService } from '@/services/api/plans.service';
import { RouterService } from '@/services/router.service';
import { UsersService } from '@/services/users.service';
import { TranslationService } from '@/services/translation.service';

import { Categoria } from '@/models/categoria';
import { User } from '@/models/user.model';
import { CONST } from '@/constants';
import { default as ReactAppbar } from '@/react/components/appbar';
import { Menus, UserMenu, MenuType } from './menus';
import { CompanyPlansResponse } from '@/models/responses/companyPlans.model';
import { SubsidiesService } from '@/services/api/subsidies.service';
import { UnibradPosService } from '@/services/unibrad_pos/unibrad.service';

@Component({
  selector: 'component-react-appbar',
  templateUrl: './appbar.component.html',
})
export class ReactAppbarComponent implements OnInit {
  user: User;
  plan: CompanyPlansResponse;
  categoria: string;
  mainMenu: MenuType[];
  userMenuGroups: Array<MenuType[]>;
  categorias: Categoria[] = [];
  activeRoute: string[] = [];

  ReactAppbar = ReactAppbar;
  reactAppbarProps: ComponentProps<typeof ReactAppbar> = {
    mainMenu: [],
    onMenuClick: (menu) => this.onMenuClick(menu),
    user: {},
    plan: {},
    currentUrl: '',
    userMenuGroups: [],
    zoomLMSService: this.zoomLMSService,
  };

  @ViewChild('categoriesMenu')
  categoriesMenu: ElementRef<HTMLDivElement>;

  @ViewChild('menuToggle')
  menuToggle: ElementRef<HTMLDivElement>;

  constructor(
    private userService: UsersService,
    private router: Router,
    private routerService: RouterService,
    private translate: TranslationService,
    private plansService: PlansService,
    private layoutService: LayoutService,
    private zoomLMSService: ZoomLMSService,
    private unibradPosService: UnibradPosService,
    private subsidiesService: SubsidiesService
  ) {}

  ngOnInit() {
    this.user = this.userService.user;
    this.plan = this.plansService.plan; // TODO: remove and use the bootstrap-data api
    this.subsidiesService.eligible_for_subsidies;
    this.mainMenu = Menus(
      this.userService.user,
      this.translate,
      this.plansService.plan,
      this.layoutService,
      this.zoomLMSService
    );
    this.userMenuGroups = UserMenu(
      this.userService.user,
      this.translate,
      this.routerService,
      this.plansService.plan,
      this.subsidiesService
    );

    if (this.plansService.isLoaded) {
      this.buildPlanCategories();
      this.handlePlansUpdate();
    } else {
      this.plansService.planChange.subscribe(() => {
        this.buildPlanCategories();
        this.handlePlansUpdate();
      });
    }

    this.translate.onLangChange().subscribe(() => {
      this.mainMenu = Menus(
        this.userService.user,
        this.translate,
        this.plansService.plan,
        this.layoutService,
        this.zoomLMSService
      );
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateReactState({ currentUrl: event.url });
      }
    });

    this.updateReactState({
      currentUrl: this.router.url,
      mainMenu: this.mainMenu,
      userMenuGroups: this.userMenuGroups,
      user: this.user,
    });
  }

  buildPlanCategories() {
    this.updateReactState({
      plan: this.plansService.plan,
    });
  }

  handlePlansUpdate() {
    this.mainMenu = Menus(
      this.userService.user,
      this.translate,
      this.plansService.plan,
      this.layoutService,
      this.zoomLMSService
    );
    this.userMenuGroups = UserMenu(
      this.userService.user,
      this.translate,
      this.routerService,
      this.plansService.plan,
      this.subsidiesService
    );

    this.updateReactState({
      mainMenu: this.mainMenu,
      userMenuGroups: this.userMenuGroups,
    });
  }

  updateReactState(state) {
    this.reactAppbarProps = {
      ...this.reactAppbarProps,
      zoomLMSService: this.zoomLMSService,
      unibradPosService: this.unibradPosService,
      subsidiesService: this.subsidiesService,
      ...state,
    };
  }

  isBlocked(type: string): boolean {
    return (
      this.userService.user.colaborador.empresa.is_freemium &&
      (type === 'hobbies' ||
        type === 'tecnicos' ||
        type === 'curta_duracao' ||
        type === 'desenvolvimento_pessoal' ||
        type === 'lideranca' ||
        type === 'tecnologia' ||
        type === 'escolas_internacionais' ||
        type === 'eduplay' ||
        type === 'kids')
    );
  }

  getNome() {
    return this.userService.user.getPrimeiroNome();
  }

  onNavigate(route: string[]) {
    window.location.href = `${CONST.FRONTEND_ENDPOINT}/${route.join('/')}`;
  }

  onMenuClick(menu: MenuType) {
    this.onNavigate(menu.route);
  }

  getTranslation(key: string): string {
    return `LAYOUT.APPBAR.${key}`;
  }

  getIcon(icon: string) {
    return `${CONST.API_ENDPOINT}/contents/categorias/${icon}`;
  }

  onPesquisa(curso: string) {
    this.routerService.navigate(['/cursos/pesquisa'], {
      queryParams: { curso },
    });
  }
}
