import React from 'react';
import { DisplayErrorsIndividuallyProps } from '../refund-request/invoice-step/interfaces';

export const openDisplayErrorsIntoToast = ({
  fieldTranslations, // if true, it will translate the field name
  payload,
  toast,
}: DisplayErrorsIndividuallyProps) => {
  const errorMessages: string[] = [];

  const processErrors = (prefix: string, data: Record<string, any>) => {
    for (const [key, value] of Object.entries(data)) {
      const fullKey = prefix ? `${key}` : key;

      if (Array.isArray(value)) {
        value.forEach((error) => {
          const translatedKey = fieldTranslations
            ? fieldTranslations[fullKey]
            : fullKey;
          errorMessages.push(`${translatedKey}: ${error}`);
        });
      } else if (typeof value === 'object' && value !== null) {
        processErrors(fullKey, value);
      } else if (typeof value === 'string') {
        const translatedKey = fieldTranslations
          ? fieldTranslations[fullKey]
          : fullKey;
        errorMessages.push(`${translatedKey}: ${value}`);
      }
    }
  };
  processErrors('', payload);

  const errorList = (
    <ul>
      {errorMessages.map((message, index) => (
        <li key={index}>{message}</li>
      ))}
    </ul>
  );

  toast({
    content: errorList,
    type: 'error',
  });
};
