import { useMutation } from '@tanstack/react-query';
import { IdpClient } from './idp-client';
import { Activity } from './interfaces';

export function useReorderActivities(planId: string): useReorderActivitiesType {
  const { mutateAsync: reorderActivities } = useMutation({
    mutationFn: async (activities: Activity[]) => {
      const activitiesIds = activities.map((activity) => ({ id: activity.id }));
      return IdpClient.reorderActivities(planId, activitiesIds);
    },
  });

  return {
    reorderActivities,
  };
}

type useReorderActivitiesType = {
  reorderActivities: (activities: Activity[]) => Promise<Activity[]>;
};
