import * as React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CarouselWithBaseStyle } from '@/react/components/embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { PlanCard } from '@/react/components/idp/home/components/plan-card.component';
import { Activity, ActivityStatuses, Plan } from '@/react/data/idp/interfaces';
import { ActivityCard } from '@/react/components/idp/shared/activity-card.component';
import { ActivityProgress } from '@/react/components/idp/activity-progress.component';

const Styled = {
  Carousel: styled(CarouselWithBaseStyle)(({ theme }) => {
    return {
      '& .embla__slide': {
        [theme.breakpoints.up('md')]: {
          flex: '0 0 29%',
        },
      },
    };
  }),
};

export const PlanCardInProgress = ({
  plan,
  activities,
}: {
  plan: Plan;
  activities: Activity[];
}) => {
  const theme = useTheme();
  const emblaCarousel = useEmblaCarousel({
    align: 'start',
    dragFree: true,
    loop: false,
    slidesToScroll: 1,
    breakpoints: {
      [`(min-width: ${theme.breakpoints.values.md}px)`]: {
        slidesToScroll: 2,
      },
      [`(min-width: ${theme.breakpoints.values.lg}px)`]: {
        slidesToScroll: 3,
      },
    },
  });

  const hasActivities = activities.length > 0;

  const activitySlidesComponents = activities.map((activity) => (
    <ActivityCard
      planId={plan.id}
      planStatus={plan.status}
      activity={activity}
      key={activity.id}
    />
  ));

  const activitiesSeparatedByStatus = React.useMemo(() => {
    return Activity.getAmountOfActivitiesByStatus(activities);
  }, [activities]);

  return (
    <PlanCard plan={plan}>
      <Stack spacing={2}>
        <Box>
          <Typography variant="body3" data-testid="planCardInProgressDates">
            {plan.startedAt} - {plan.estimatedEndsAt}
          </Typography>
        </Box>
        {hasActivities && (
          <>
            <Box>
              <Typography gutterBottom variant="body3" fontWeight="bold">
                Progresso
              </Typography>
              <ActivityProgress
                data={{
                  active:
                    activitiesSeparatedByStatus[ActivityStatuses.IN_PROGRESS],
                  done: activitiesSeparatedByStatus[ActivityStatuses.COMPLETED],
                  remaining:
                    activitiesSeparatedByStatus[ActivityStatuses.PENDING],
                }}
              />
            </Box>
            <Box>
              <Typography gutterBottom variant="body3" fontWeight="bold">
                Atividades
              </Typography>
              <Styled.Carousel
                instance={emblaCarousel}
                navigation={false}
                slidesComponents={activitySlidesComponents}
              />
            </Box>
          </>
        )}
      </Stack>
    </PlanCard>
  );
};
