import * as React from 'react';
import Button from '@mui/material/Button';

import { BaseDialog } from './base-dialog.component';

export const defaultActionLabels = {
  cancel: 'Cancelar',
  confirm: 'Confirmar',
};

export interface ConfirmDialogProps {
  actionLabels?: {
    cancel?: string;
    confirm?: string;
  };
  children: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  title?: string | React.ReactNode;
}

export const ConfirmDialog = ({
  actionLabels = {
    cancel: defaultActionLabels.cancel,
    confirm: defaultActionLabels.confirm,
  },
  children,
  onClose,
  onConfirm,
  open,
  title,
}: ConfirmDialogProps) => {
  const Actions = () => {
    return (
      <>
        <Button onClick={onClose} variant="text">
          {actionLabels.cancel}
        </Button>
        <Button onClick={onConfirm} variant="contained">
          {actionLabels.confirm}
        </Button>
      </>
    );
  };

  return (
    <BaseDialog
      actionsComponent={<Actions />}
      disableEscapeKeyDown
      onClose={onClose}
      open={open}
      title={title}
    >
      {children}
    </BaseDialog>
  );
};
