<div class="container">
  <div class="row" *ngIf="isDeloitte()">
    <div class="col-12">
      <app-deloitte-terms></app-deloitte-terms>
    </div>
  </div>
  <ng-container *ngIf="!isDeloitte()">
    <app-loading *ngIf="loading"></app-loading>

    <ng-container *ngIf="!loading">
      <div class="row justify-content-center">
        <iframe
          class="w-100 terms-iframe"
          src="https://assets.galena.com/edupass-api/public/termo-de-uso.pdf#view=FitH&toolbar=0&navpanes=0"
        ></iframe>
      </div>
    </ng-container>
  </ng-container>

  <div class="row">
    <div class="col-12">
      <div *ngIf="mensagemErro" class="my-3">
        <p class="error">
          Você deve indicar abaixo que leu, e que concorda com os Termos de Uso
          e Política de Privacidade.
        </p>
      </div>

      <div class="my-3 d-flex justify-content-center">
        <component-checkbox [(ngModel)]="checkbox">
          Li e concordo com os Termos de Uso e Política de Privacidade.
        </component-checkbox>
      </div>
      <div class="d-flex justify-content-center mb-3">
        <component-botao (click)="onEnviar()">Enviar</component-botao>
      </div>
    </div>
  </div>
</div>
