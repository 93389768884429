import React, { Dispatch, SetStateAction, useEffect } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography, { TypographyTypeMap } from '@mui/material/Typography';

import { useNotification } from '@/react/components/notification';
import { ConfirmDeleteActivityDialog } from '@/react/components/idp/dialogs/confirm-delete-activity-dialog.component';
import { ErrorResponse, IdpClient } from '@/react/data/idp/idp-client';
import { Activity, PlanStatuses } from '@/react/data/idp/interfaces';
import { InlineEditInput } from '@/react/components/form/inline-edit-input.component';
import { PageContainer, SectionHeader } from '@/react/components/idp/shared';
import { usePlanActivity } from '@/react/data/idp';
import DeleteIcon from '@mui/icons-material/Delete';
import { PageSkeleton } from '@/react/components/idp/shared';
import { useSetTitle } from '@/react/hooks/useSetTitle';
import { usePlan } from '@/react/data/idp';

type TUpdateField = (
  activity: Activity,
  title: string,
  description: string,
  value: string
) => Promise<boolean>;

type ComponentProps = {
  activity: Activity;
  updateField: TUpdateField;
  disabled?: boolean;
};

type HeaderProps = {
  setOpen: Dispatch<SetStateAction<boolean>>;
} & ComponentProps;

type ComponentFetchedProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
} & ComponentProps;

type FieldArgs = {
  field: string;
  placeholder?: string;
  title?: string;
  variant?: TypographyTypeMap['props']['variant'];
} & ComponentProps;

const backToPlanListLink = (activityPlanId: string) => {
  window.location.assign(`/pdi/planos/${activityPlanId}`);
};

const FieldComponent = ({
  activity,
  field,
  placeholder,
  title,
  updateField,
  variant,
  ...rest
}: FieldArgs) => {
  const onAccept = async (value: string): Promise<boolean> => {
    return await updateField(activity, title, field, value);
  };

  const value = activity[field] || '';

  return (
    <FormControl fullWidth>
      {title && (
        <FormLabel htmlFor={field}>
          <Typography variant="body3" fontWeight="bold">
            {title}
          </Typography>
        </FormLabel>
      )}
      <Typography
        {...rest}
        component={InlineEditInput}
        fullWidth
        id={field}
        onAccept={onAccept}
        placeholder={placeholder}
        value={value}
        variant={variant}
      />
    </FormControl>
  );
};

const Header = ({ activity, updateField, setOpen, disabled }: HeaderProps) => {
  const actions = [
    {
      label: 'Deletar Atividade',
      onClick: () => setOpen(true),
      Icon: <DeleteIcon />,
    },
  ];

  const value = activity.title || '';

  return (
    <SectionHeader
      actions={actions}
      handleBackNavigation={() => backToPlanListLink(activity.planId)}
    >
      <Box width="100%">
        <Typography
          component={InlineEditInput}
          data-testid="title-input"
          fullWidth
          onAccept={async (value: string): Promise<boolean> => {
            return await updateField(activity, 'Título', 'title', value);
          }}
          placeholder="Insira um título"
          value={value}
          variant="h5"
          disabled={disabled}
        />
      </Box>
    </SectionHeader>
  );
};

const ComponentFetched = ({
  activity,
  open,
  setOpen,
  updateField,
  disabled,
}: ComponentFetchedProps) => {
  return (
    <Grid container spacing={3} data-testid="show-activity">
      <Grid item xs={12}>
        <Header
          activity={activity}
          setOpen={setOpen}
          updateField={updateField}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <FieldComponent
          activity={activity}
          data-testid="description-input"
          field="description"
          placeholder="Insira uma descrição"
          title="Descrição"
          updateField={updateField}
          variant="body3"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <FieldComponent
          activity={activity}
          data-testid="resource-url-input"
          field="resourceUrl"
          placeholder="Insira o link do curso"
          title="Link do curso"
          updateField={updateField}
          variant="body3"
          disabled={disabled}
        />
      </Grid>
      <ConfirmDeleteActivityDialog
        open={open}
        onClose={() => setOpen(false)}
        activity={activity}
      />
    </Grid>
  );
};

const ShowActivity = ({ activityPlanId, activityId }) => {
  const [open, setOpen] = React.useState(false);
  const { toast } = useNotification();
  const {
    activity: fetchedActivity,
    error,
    isFetching: fetchingActivity,
  } = usePlanActivity({ activityId, planId: activityPlanId });

  const { plan, isFetching: fetchingPlan } = usePlan(activityPlanId);
  const [activity, setActivity] = React.useState<Activity>(null);

  const planTitle = plan?.title ?? 'Plano sem título';

  useSetTitle(
    activity?.title && planTitle
      ? `"${activity?.title}" - ${planTitle}`
      : undefined
  );

  if (error) {
    backToPlanListLink(activityPlanId);
  }

  const updateField = async (
    activity: Activity,
    title: string,
    field: string,
    value: any
  ): Promise<boolean> => {
    const response = await IdpClient.updateActivityField(
      activity,
      field,
      value
    );

    if (response instanceof Activity) {
      setActivity(response);
      return true;
    } else if (response instanceof ErrorResponse) {
      toast({
        content: <Error error={`Erro ao editar ${title}`} />,
        type: 'error',
      });
    }

    return false;
  };

  const Error = ({ error }) => {
    return <div>{error}</div>;
  };

  useEffect(() => {
    setActivity(fetchedActivity);
  }, [fetchedActivity]);

  const isComponentReady = activity && !fetchingPlan;

  return (
    <PageContainer>
      <Box sx={{ py: 3 }}>
        {fetchingActivity && <PageSkeleton />}
        {isComponentReady && (
          <ComponentFetched
            activity={activity}
            open={open}
            setOpen={setOpen}
            updateField={updateField}
            disabled={plan.status === PlanStatuses.CLOSED}
          />
        )}
      </Box>
    </PageContainer>
  );
};

export { ShowActivity };
