import React from 'react';

import { IdpConfirmDialog } from '@/react/components/idp/dialogs';
import { Plan } from '@/react/data/idp/interfaces';

export interface StartPlanDisabledDialogProps {
  onClose: () => void;
  open: boolean;
}

export const StartPlanDisabledDialog = ({
  onClose,
  open,
}: StartPlanDisabledDialogProps) => {
  return (
    <IdpConfirmDialog
      actionLabels={{
        confirm: 'Entendi',
        cancel: 'Fechar',
      }}
      description="Para iniciar o plano é necessário definir a duração e adicionar pelo menos uma atividade."
      onClose={onClose}
      onConfirm={onClose}
      open={open}
      subtitle=""
      title={'Você ainda não pode iniciar este plano'}
    />
  );
};
