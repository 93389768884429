import * as React from 'react';
import Container from '@mui/material/Container';
import { useQuery } from '@tanstack/react-query';
import { constants } from '@/react/constants';

import { CursosSection } from './section.component';
import CourseTypeList from './course-type-list.component';
import CourseTypeListLoading from './course-type-list-loading.component';
import KnowledgeAreaList from './knowledge-area-list.component';
import KnowledgeAreaListLoading from './knowledge-area-list-loading.component';
import { Carousel, CarouselSlide } from '@/react/components/carousel';

import ContentContainer from '@/react/components/layout/content-container.component';
import { restClient } from '@/react/utils/fetch';
import CONST from '@/constants';
import { User } from '@/models/user.model';
import { BootstrapData, CourseType } from './home.interfaces';
import { HomeRecommendations } from './home-recommendations';
import { GtmContext } from '../state/context';
import axios from 'axios';

// FIXME: This should consider production/staging/development envs
const baseUrl = 'https://assets.galena.com/edupass-api/public/home-banners';

const slides: CarouselSlide[] = [
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'banner_1',
      creative_name: 'creative_name_banner_80_off',
      creative_slot: 'creative_slot_banner_80_off',
      promotion_id: 'promotion_id_banner_80_off',
      promotion_name: 'promotion_name_banner_80_off',
    },
    objectFit: 'cover',
    src: `${baseUrl}/banners/Banner-1-P-20241209.png`,
    link: '/cursos/novo_catalogo?page=1',
    md: {
      src: `${baseUrl}/banners/Banner-1-M-20241209.png`,
    },
    lg: {
      src: `${baseUrl}/banners/Banner-1-G-20241209.png`,
    },
    title: `Banner promocional dizendo: 'Porque deixar para 2025 o que você pode começar em 2024'`,
  },
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'banner_2',
      creative_name: 'creative_name_banner_uniciv',
      creative_slot: 'creative_slot_banner_uniciv',
      promotion_id: 'promotion_id_banner_uniciv',
      promotion_name: 'promotion_name_banner_uniciv',
    },
    objectFit: 'cover',
    src: `${baseUrl}/banners/Banner-2-P-20241209.png`,
    link: '/cursos/novo_catalogo?page=1&institutions=339',
    md: {
      src: `${baseUrl}/banners/Banner-2-M-20241209.png`,
    },
    lg: {
      src: `${baseUrl}/banners/Banner-2-G-20241209.png`,
    },
    title: `Banner promocional dizendo: 'Para você e sua carreira: estude idiomas com a UPCIV'`,
  },
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'banner_3',
      creative_name: 'creative_name_banner_estacio_desenvolvimento',
      creative_slot: 'creative_slot_banner_estacio_desenvolvimento',
      promotion_id: 'promotion_id_banner_estacio_desenvolvimento',
      promotion_name: 'promotion_name_banner_estacio_desenvolvimento',
    },
    objectFit: 'cover',
    src: `${baseUrl}/banners/Banner-3-P-20241209.png`,
    link: '/cursos/novo_catalogo?page=1&institutions=4&course_types=technical',
    target: '_blank',
    md: {
      src: `${baseUrl}/banners/Banner-3-M-20241209.png`,
    },
    lg: {
      src: `${baseUrl}/banners/Banner-3-G-20241209.png`,
    },
    title: `Banner promocional dizendo: 'Cursos técnicos para seu desenvolvimento'`,
  },
  {
    backgroundColor: '#eef3fa',
    tagsGTM: {
      id: 'banner_4',
      creative_name: 'creative_name_banner_new_partners',
      creative_slot: 'creative_slot_banner_new_partners',
      promotion_id: 'promotion_id_banner_new_partners',
      promotion_name: 'promotion_name_banner_new_partners',
    },
    objectFit: 'cover',
    src: `${baseUrl}/banners/Banner-4-P-20241209.png`,
    link: '/cursos/novo_catalogo?institutions=366,367&page=1',
    target: '_blank',
    md: {
      src: `${baseUrl}/banners/Banner-4-M-20241209.png`,
    },
    lg: {
      src: `${baseUrl}/banners/Banner-4-G-20241209.png`,
    },
    title: `Banner promocional dizendo: 'Novos parceiros acabaram de chegar'`,
  },
];

const CursosHome = (props: { user: User | null }) => {
  const { user } = props;

  const { isPending: isBootstrapDataPending, data: bootstrapData } =
    useQuery<BootstrapData>({
      queryKey: ['bootstrapData'],
      queryFn: () =>
        restClient.get('/users/bootstrap-data').then((res) => res.data.data),
    });

  const { info, plan, taxonomy } = bootstrapData || {};
  const courseTypes = taxonomy?.course_types || [];
  const knowledgeAreas = taxonomy?.knowledge_areas || [];

  const landingPageFeature = plan?.features.filter(
    (item) => item.name === 'unibrad_pos'
  );

  if (landingPageFeature && landingPageFeature.length > 0) {
    axios.get(plan?.promo_landing_page.url, {
      headers: {
        Authorization: `${plan?.promo_landing_page.token}`,
      },
    });

    slides.unshift({
      backgroundColor: '#CC092F',
      tagsGTM: {
        id: 'Novidade',
        creative_name: 'Summer Banner',
        creative_slot: 'featured_app_1',
        promotion_id: 'P_12345',
        promotion_name: 'Summer Sale',
      },
      objectFit: 'cover',
      src: `${baseUrl}/unialphaville-bradesco-nov-2024/Banner-1-P.png`,
      link: `${plan?.promo_landing_page.url}`,
      target: '_blank',
      md: {
        src: `${baseUrl}/unialphaville-bradesco-nov-2024/Banner-1-M.png`,
      },
      lg: {
        src: `${baseUrl}/unialphaville-bradesco-nov-2024/Banner-1-G.png`,
      },
      title: `Unibrad - Pós-graduação por R$32,50`,
    });
  }

  const fullIncluded = plan?.features?.some(
    (el) => el.name === 'full_included'
  );

  const customCategory = info?.colaborador.empresa.custom_category;

  const universityCard = ((): CourseType | null => {
    const label = user?.colaborador?.empresa?.corporate_university_name;
    const logo = user?.colaborador?.empresa?.logo;

    return label && logo
      ? {
          label,
          link: '/corporate_university/trilhas',
          image: `${CONST.API_ENDPOINT}/contents/categorias/${logo}`,
          description: '',
          type: 'university',
        }
      : null;
  })();

  const courseTypesWithLink = (() => {
    const cards = [];

    if (landingPageFeature && landingPageFeature.length > 0) {
      cards.unshift({
        description: 'Aproveite essa exclusividade da Unibrad.',
        image: `${baseUrl}/unialphaville-bradesco-nov-2024/Banner Card-M.png`,
        label: 'Pós-graduação UniBrad',
        link: `${plan?.promo_landing_page.url}`,
        type: 'custom-category',
      });
    }

    if (universityCard) {
      cards.push(universityCard);
    }

    if (customCategory) {
      cards.push({
        description: 'Recomendados pela sua empresa',
        image: `${constants.API_ENDPOINT}/contents/categorias/${info.colaborador.empresa.logo}`,
        label: customCategory,
        link: '/cursos/custom-category',
        type: 'custom-category',
      });
    }

    if (fullIncluded) {
      cards.push({
        label: 'Inclusos no seu plano',
        link: 'cursos/novo_catalogo?feature_full=true',
        type: 'full',
      });
    }

    courseTypes.forEach((courseType) => {
      cards.push({
        ...courseType,
        link: `cursos/novo_catalogo?course_types=${courseType.type}`,
      });
    });

    return cards;
  })();

  const knowledgeAreasWithLink = knowledgeAreas.map((knowledgeArea) => ({
    ...knowledgeArea,
    link: `cursos/novo_catalogo?knowledge_areas=${knowledgeArea.id}`,
  }));

  return (
    <Container fixed maxWidth="xl">
      <ContentContainer>
        <GtmContext>
          <CursosSection>
            <Carousel infinite={slides.length > 1} slides={slides} />
          </CursosSection>

          <HomeRecommendations />
        </GtmContext>

        <CursosSection title="Encontre o curso ideal para você">
          {isBootstrapDataPending && <CourseTypeListLoading />}
          {!isBootstrapDataPending && courseTypesWithLink.length > 0 && (
            <CourseTypeList data={courseTypesWithLink} />
          )}
        </CursosSection>

        <CursosSection title="Explore cursos por Temas">
          {isBootstrapDataPending && <KnowledgeAreaListLoading />}
          {!isBootstrapDataPending && knowledgeAreasWithLink.length > 0 && (
            <KnowledgeAreaList data={knowledgeAreasWithLink} />
          )}
        </CursosSection>
      </ContentContainer>
    </Container>
  );
};

export default CursosHome;
