import { Routes as CursosRoutes } from '@/pages/cursos/cursos.routes';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';

import { CursoHorario } from '@/models/curso-horario.model';
import { User } from '@/models/user.model';
import { Coupon } from '@/models-new/coupon.model';

import { ApiService } from '@/services/api.service';
import { CoursesService } from '@/services/api2/courses.service';
import { UsersService } from '@/services/users.service';
import { RouterService } from '@/services/router.service';
import { CouponService } from '@/services/api2/coupon.service';
import { CepService } from '@/services/cep.service';

import { getAsset, getSvg } from '@/utils/helpers';
import { extractNumber } from '@/utils/form-mask/number';
import { phoneMask, phoneValidator } from '@/utils/form-mask/telefone';
import { cpfMask, cpfValidator } from '@/utils/form-mask/cpf';
import { dateValidator } from '@/utils/form-mask/date';
import { cepMask, postalCodeValidator } from '@/utils/form-mask/cep';

import { GoogleTagManager } from '@/libs/googleTagManager';
import { OfferWithDetails } from '@/react/data/catalog/interfaces';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private routerService: RouterService,
    private userService: UsersService,
    private couponService: CouponService,
    private cepService: CepService,
    private CoursesService: CoursesService
  ) {}

  curso: CursoHorario;

  form: UntypedFormGroup;

  route: string[];

  formSubmitted = false;
  showCongratiolationsModal = false;

  posting = false;

  loading: boolean = true;

  coupon: string = '';
  couponError: boolean = false;
  checkingCoupon: boolean = false;
  couponSection: boolean = false;

  appliedCoupon: Coupon;

  user: User;

  gtm: GoogleTagManager;

  ngOnInit() {
    this.user = this.userService.user;

    this.gtm = new GoogleTagManager();

    this.activatedRoute.params.subscribe(({ id }) => {
      this.loadOffer(id);
    });

    this.form = new UntypedFormGroup({
      nome: new UntypedFormControl(this.user.nome, Validators.required),
      birth_date: new UntypedFormControl(
        // TODO: Bring this from User.birth_date when implemented
        undefined,
        [Validators.required, dateValidator]
      ),
      email: new UntypedFormControl(undefined, [
        Validators.required,
        Validators.email,
      ]),
      telefone: new UntypedFormControl(phoneMask(this.user.phone), [
        Validators.required,
        phoneValidator,
      ]),
      cupom: new UntypedFormControl(''),
      cpf: new UntypedFormControl(cpfMask(this.user.cpf), [
        Validators.required,
        cpfValidator,
      ]),

      // TODO: Load Address attributes from User when available
      full_address: new UntypedFormGroup({
        postal_code: new UntypedFormControl('', [
          Validators.required,
          postalCodeValidator,
        ]),
        city: new UntypedFormControl({ value: '', disabled: true }, []),
        city_ibge_code: new UntypedFormControl(
          { value: '', disabled: true },
          []
        ),
        state: new UntypedFormControl({ value: '', disabled: true }, []),
        neighborhood: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        street: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(3),
        ]),
        number: new UntypedFormControl('', Validators.required),
        complement: new UntypedFormControl('', []),
      }),
    });
  }

  async loadOffer(offerId: number) {
    const offer = await this.CoursesService.getById(offerId);

    this.curso = CursoHorario.fromOfferWithDetails(offer);

    if (CursoHorario.isEduplay(this.curso)) {
      this.routerService.navigate(['eduplay', this.curso.id]);
    }
    if (CursoHorario.isMarketable(this.curso, this.user)) {
      this.routerService.navigate(['cursos', this.curso.id, 'checkout']);
    } else {
      this.gtm.trackSubscriptionEvent(this.curso);
    }
    this.loading = false;
  }

  getAsset = getAsset;
  getSvg = getSvg;

  onPhoneBlur(value: string) {
    this.form.controls.telefone.setValue(phoneMask(extractNumber(value)));
  }

  onCpfBlur(value: string) {
    this.form.controls.cpf.setValue(cpfMask(extractNumber(value)));
  }

  async onPostalCodeBlur() {
    const postal_code = this.form.get('full_address.postal_code');
    this._autocompleteAddress({});

    if (!postal_code.valid) {
      return;
    }

    postal_code.setValue(cepMask(postal_code.value));

    const payload = await this.cepService.getCepData(postal_code.value);

    if (payload.erro) {
      postal_code.setErrors({ postal_code: 'CEP não encontrado' });
    } else {
      this._autocompleteAddress(payload);
    }
  }

  showErrorForField(fieldKey: string): boolean {
    const field = this.form.get(fieldKey);

    return field && !field.valid && (field.touched || this.formSubmitted);
  }

  errorForField(fieldKey: string): string {
    const field = this.form.get(fieldKey);

    if (!field?.errors) {
      return;
    }

    if (field.errors.required) {
      return 'É inválido';
    }

    return Object.values(field.errors)[0];
  }

  _autocompleteAddress(payload: any) {
    this.form.get('full_address.street').setValue(payload.logradouro);
    this.form.get('full_address.neighborhood').setValue(payload.bairro);
    this.form.get('full_address.city').setValue(payload.localidade);
    this.form.get('full_address.city_ibge_code').setValue(payload.ibge);
    this.form.get('full_address.state').setValue(payload.uf);
  }

  getDesconto() {
    return CursoHorario.formattedDiscount(this.curso, this.userService.user);
  }

  async checkCoupon() {
    this.checkingCoupon = true;

    try {
      this.appliedCoupon = await this.couponService.checkCoupon(
        this.coupon,
        this.curso.id
      );
      this.couponSection = false;
      this.curso.desconto = CursoHorario.calculatedDiscount(
        this.curso,
        this.appliedCoupon
      );
      this.form.controls.cupom.setValue(this.appliedCoupon.code);
    } catch (e) {
      this.checkingCoupon = false;
      this.couponError = true;
    }
  }

  getCouponDiscount(discount: number) {
    return Math.round(discount);
  }

  _buildRequest() {
    const form = this.form.getRawValue();

    let legacyAddress = [
      `${form.full_address.street} `,
      `${form.full_address.number}, `,
      form.full_address.complement ? `${form.full_address.complement}, ` : '',
      `${form.full_address.neighborhood}, `,
      `${form.full_address.postal_code} - `,
      `${form.full_address.city}, `,
      form.full_address.state,
    ].join('');

    const unmasked = {
      cpf: extractNumber(form.cpf),
      telefone: extractNumber(form.telefone),
    };

    return {
      ...form,
      ...unmasked,
      address: legacyAddress,
      full_address: {
        ...form.full_address,
        postal_code: extractNumber(form.full_address.postal_code),
      },
    };
  }

  private mapErrorMessagesToFields(errorObject) {
    const errorMessages = errorObject.message.split('; ');

    for (const errorMessage of errorMessages) {
      let [fieldName, error] = errorMessage.split(': ');

      if (fieldName.includes('/')) {
        fieldName = fieldName.replace('/', '.');
      }

      const field = this.form.get(fieldName);
      field.setErrors(this.form.get(fieldName).errors || {}, {
        emitEvent: true,
      });

      this.showErrorForField(fieldName);
      this.errorForField(fieldName);
    }
  }

  onSubmit() {
    const body = this._buildRequest();

    this.formSubmitted = true;

    if (this.form.valid && !this.posting) {
      this.gtm.trackFinishSubscriptionEvent(this.curso);

      this.posting = true;
      this.apiService
        .post(`cursos/${this.curso.id}/registration`, body)
        .subscribe(
          (response) => {
            // TODO: what about error?
            this.posting = false;
            this.showCongratiolationsModal = true;
            this.gtm.trackSubscriptionSuccessEvent(
              this.curso,
              this.appliedCoupon
            );
          },
          (error) => {
            const { data } = error.error;
            this.mapErrorMessagesToFields(data);
            this.posting = false;
            this.formSubmitted = false;
          }
        );
    }
  }

  closeCongratiolationsModal() {
    this.routerService.navigate([CursosRoutes.CURSOS]);
  }

  validateForm(): boolean {
    return this.form.valid && !this.couponSection;
  }

  getTranslation(key: string): string {
    return `CURSOS.REGISTRATION.${key}`;
  }
}
