import * as React from 'react';
import { styled } from '@mui/material/styles';
import { UseEmblaCarouselType } from 'embla-carousel-react';

import baseCarouselStyle from './base-style';
import {
  NavigationButton,
  usePagination,
} from './base-carousel-pagination.component';
import {
  PrevButton,
  NextButton,
  useNavigation,
} from './base-carousel-navigation.component';

const Carousel = ({
  className,
  instance,
  navigation = false,
  pagination = false,
  slidesComponents,
}: {
  instance: UseEmblaCarouselType;
  className?: string;
  pagination?: boolean;
  navigation?: boolean;
  slidesComponents: React.ReactNode[];
}) => {
  const [emblaRef, emblaApi] = instance;

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    usePagination(emblaApi);

  const {
    prevButtonDisabled,
    nextButtonDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = useNavigation(emblaApi);

  return (
    <div className={`embla ${className}`}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slidesComponents.map((slide, index) => (
            <div className="embla__slide" key={index}>
              {slide}
            </div>
          ))}
        </div>
        {navigation && (
          <>
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevButtonDisabled}
            />
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextButtonDisabled}
            />
          </>
        )}
      </div>

      {pagination && (
        <div className="embla__pagination">
          {scrollSnaps.map((_, index) => (
            <NavigationButton
              active={index === selectedIndex}
              key={index}
              onClick={() => onDotButtonClick(index)}
              className="embla__dot"
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const CarouselWithBaseStyle = styled(Carousel)(({ theme }) => ({
  ...baseCarouselStyle({ theme }),
}));

export default Carousel;
