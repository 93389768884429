import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { useScreenSizes } from '@/react/utils/useScreenSizes';

export type BaseDialogProps = {
  actionsComponent?: React.ReactNode;
  onClose: () => void;
  title?: string | React.ReactNode;
} & Omit<DialogProps, 'onClose' | 'title'>;

export const BaseDialog = ({
  actionsComponent,
  children,
  onClose,
  title,
  ...props
}: BaseDialogProps) => {
  const { isSmallScreen } = useScreenSizes();
  const { disableEscapeKeyDown = false, open } = props;

  const DefaultActions = () => (
    <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={1}>
      <Button color="primary" onClick={onClose} variant="outlined">
        Fechar
      </Button>
    </Stack>
  );

  const actions = actionsComponent || <DefaultActions />;

  return (
    <Dialog
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle>
        <Stack
          sx={{
            alignItems: 'flex-start',
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'flex-end',
          }}
        >
          {title && <Box sx={{ flex: 1 }}>{title}</Box>}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: ({ palette }) => palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Stack
          sx={{
            flexDirection: isSmallScreen ? 'column' : 'row',
            justifyContent: isSmallScreen ? 'center' : 'flex-end',
            width: '100%',

            '& > button': {
              width: isSmallScreen ? '100%' : 'auto',
            },
          }}
          spacing={1}
        >
          {actions}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
